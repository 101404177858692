<template>
  <v-container fill-height>
      <v-layout wrap align-center justify-center>
          <v-progress-linear v-if="loading" color="teal" :indeterminate="true"></v-progress-linear>
          <v-flex xs12 sm6 v-else>
              <v-card v-if="!invoice.amount">
                  <v-card-title>
                      <div>
                          <h3 class="headline mb-0 teal--text">Meine Rechungen</h3>
                          <div>Demnächst bekannt</div>
                      </div>
                  </v-card-title>
              </v-card>
              <v-card v-else>
                  <v-card-title primary-title>
                      <div>
                          <h3 class="headline mb-0 teal--text">Rechnung</h3>
                          <div>Bezahlbar bis: 31. März 2024</div>
                      </div>
                  </v-card-title>
                  <v-card-text>
                      <b>{{ invoice.amount }} CHF</b>
                      <p><br/>Raiffeisenbank Seerücken Pfyn<br/>IBAN: <b>CH75 8080 8002 5937 6352 5<br/>Familienverein Pfyn<br/>8505 Pfyn</b></p>
                      <p><span style="color:red">ZAHLUNGEN VIA POSTSCHALTER SIND NICHT MÖGLICH!!</span></p>
                      <p><b>Die Rechnung muss spätestens bis am 30. März 2024 beglichen sein. Bei Nichteinhaltung der Zahlungsfrist wird eine Mahngebühr von CHF 10.00 verrechnet!</b></p>
                  </v-card-text>
              </v-card>
          </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
export default {
    name: 'Invoices',
    data: () => ({
        invoice: null,
        loading: true
    }),
    methods: {
        fetchData: function(){
            this.$axios.get('/my/account/invoice')
            .then(response => this.invoice = response.data)
            .catch(error => console.log(error))
            .finally(() => this.loading = false)
        }
    },
    created: function(){
        this.fetchData();
    }
}
</script>

<style>

</style>