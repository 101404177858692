<template>
    <v-layout wrap>
        <v-flex xs12 my-2>
            <h5 class="headline teal--text">Kurslisten Ferienpass:</h5>
        </v-flex>
        <v-flex xs12>
            <v-data-table :headers="headers" :items="$store.getters.confirmedCourses" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>{{ formatCourseDateTime(item) }}</td>
                        <td>{{ item.maxSeats - item.freeSeats }}/{{ item.maxSeats }}</td>
                        <td>
                            <v-btn v-if="item.helpers" text icon color="teal" @click="showHelpers(item.id)">
                                <v-icon>assistant</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn text icon color="teal" @click="showParticipants(item.id)">
                                <v-icon>format_list_bulleted</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <!-- list of participants -->
        <v-dialog v-model="participantsDialog" scrollable persistent :overlay="false" max-width="500px" transition="dialog-transition">
             <v-card>
                <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                    <span>Teilnehmerliste</span>
                </v-card-title>
                <v-card-text class="subheading">
                    <v-progress-linear v-if="!participants" color="teal" :indeterminate="true"></v-progress-linear>
                    <v-list v-else ref="participantList">
                        <v-subheader>
                            {{ $store.getters.getCourseById(participants[0].CourseBooking.courseId).title }} - {{ $store.getters.getDisplayDate($store.getters.getCourseById(participants[0].CourseBooking.courseId).start, $store.getters.getCourseById(participants[0].CourseBooking.courseId).end) }}
                        </v-subheader>
                        <template v-for="(participant, index) in participants">
                            <v-list-item :key="index">
                                <v-list-item-content>
                                    <v-list-item-title>{{ participant.firstname }} {{ participant.lastname }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetParticipants()">
                        Schliessen
                    </v-btn>
                    <v-btn text icon color="teal" @click="print($store.getters.getCourseById(participants[0].CourseBooking.courseId))" :disabled="!participants">
                        <v-icon>print</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <HelperDialog :dialog="helpersDialog" :helpers="helpers" @closeHelpers="closeHelpers"/>
    </v-layout>
</template>

<script>
import jsPDF from 'jspdf'
import HelperDialog from '@/components/admin/rollout/HelperDialog.vue'

export default {
    name: 'AfterEventConfirmed',
     components: {
        HelperDialog
    },
    data: () => ({
        headers: [
            { text: 'Titel', value: 'title' },
            { text: 'Wann', value: 'start'},
            { text: '#Teilnehmer', value: 'participants'},
            { text: 'Helfer' },
            { text: 'Teilnehmerliste' },
        ],
        participantsDialog: false,
        participants: null,
        helpersDialog: false,
        helpers: null
    }),
    methods: {
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD. MMMM')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD. MMMM YYYY')
            } else {
                dateString += ' ' + this.$moment(course.start).format('YYYY')
            }
            dateString += ', ' + course.startsAt + ' - ' + course.endsAt + ' Uhr'
            return dateString
        },
        resetParticipants: function(){
            this.participantsDialog = false
            this.participants = null
        },
        showParticipants: function(courseId){
            this.getParticipants(courseId)
            this.participantsDialog = true
        },
        getParticipants: function(courseId){
            this.$axios.get('/courses/'+courseId+'/participants')
            .then(response => {
                this.participants = response.data
            })
            .catch(error => console.log(error))
        },
        print: function(course){
            let doc = new jsPDF('portrait', 'mm', 'a4')
            let kids = []
            doc.text(course.title + " - " + this.formatCourseDateTime(course), 20, 20)
            this.participants.forEach(person => {
                kids.push(person.firstname + " " + person.lastname + " (" + person.age+ ")")
            })
            doc.setFontSize(12)
            doc.text(kids, 20, 50, {
                lineHeightFactor: "1.5"
            })
            doc.save('Teilnehmer - '+course.title+'.pdf')
        },
        showHelpers: function(courseId){
            this.helpersDialog = true
            this.$axios.get('/courses/'+courseId+'/helpers')
            .then(result => {
                this.helpers = result.data
            })
            .catch(error => console.log(error))
        },
        closeHelpers: function(){
            this.helpersDialog = false,
            this.helpers = null
        },
    }
}
</script>