<template>
  <v-app v-if="browserOk">
    <NavbarGuest @navigateTo="onNavigateTo" />
    <v-main>
      <v-progress-linear v-if="!(sessionRefreshed && eventDataLoaded)" color="teal" :indeterminate="true"></v-progress-linear>
      <keep-alive v-else>
        <router-view @navigateTo="onNavigateTo"></router-view>
      </keep-alive>
        <v-snackbar :value="$store.state.showSnackbar" :multi-line="$store.state.snackBarMultiLine" :color="$store.state.snackBarColor" :timeout="snackBarTimeout">
          {{ $store.state.snackBarText }}
        <v-btn dark text @click="$store.commit('toggleSnackbar')">
          Schliessen
        </v-btn>
      </v-snackbar>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container fill-height>
        <v-layout wrap justify-center align-center>
          <v-flex xs12>
            Aus technischen Gründen kann dieser Browser vom Ferienpass nicht unterstützt werden. Bitte nutzen Sie <a href="https://www.google.com/intl/de/chrome/">Google Chrome</a> oder <a href="https://www.mozilla.org/de/firefox/download/thanks/">Firefox</a>. Besten Dank
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import NavbarGuest from '@/components/NavbarGuest.vue'

  import './style.css';

  export default {
    name: 'App',
    components: {
      NavbarGuest
    },
    data() {
      return {
        snackBarTimeout: 0,
        browserOk: true,
        sessionRefreshed: false,
        eventDataLoaded: false
      }
    },
    methods: {
      onNavigateTo: function (url) {
        this.$router.push(url)
      },
      fetchData: function () {
        this.sessionRefreshed = false
        this.eventDataLoaded = false
        this.$moment.locale('de')
        this.$axios.get('/refresh')
        .then(response => {
          this.$store.commit('setUser', response.data)
          this.$axios.get('/persons')
            .then(response => {
              if(response.data.length === undefined){
                this.$store.commit('setPersons', [response.data])
              }else{
                this.$store.commit('setPersons', response.data)
              }
              
              this.$axios.get('/bookings')
                .then(response => this.$store.commit('setBookings', response.data))
            })
        })
        .catch(error => {
          if (error.response) {
            // probably session timeout
            this.$store.commit('setSnackbarText', error.response.data)
          } else {
            // backend unavailable
            this.$store.commit('setSnackbarText', 'Ferienpass-Service offline. Bitte kontaktieren Sie den Systemadministrator')
            this.$store.commit('setSnackbarColor', 'error')
            this.$store.commit('setSnackbarMultiLine', true)
          }
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => this.sessionRefreshed = true)
        this.$axios.get('/event')
        .then(response => {
          this.$store.commit('setEvent', response.data)
          this.$axios.get('/courses')
            .then(response => this.$store.commit('setCourses', response.data))
            .catch(error => console.log(error.response))
        })
        .catch(error => console.log(error.response))
        .finally(() => this.eventDataLoaded = true)
      }
    },
    created: function(){
      this.fetchData()
      this.browserOk = this.$store.getters.isBrowserCompatible
    } 
  }
</script>