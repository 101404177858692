const Moment = require('moment')
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
moment.locale('de');

export default {
    setCourseDateTime: function (course) {
        course.startsAt = moment(course.start).format('HH:mm')
        course.endsAt = moment(course.end).format('HH:mm')
        course.start = moment(course.start).local().format('YYYY-MM-DD')
        course.end = moment(course.end).local().format('YYYY-MM-DD')
    }
}