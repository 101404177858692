<template>
    <v-container grid-list-xs fill-height mt-3>
        <v-layout wrap>
            <v-flex xs12 sm6 offset-sm3>
                <v-card>
                    <v-container fluid>
                        <v-card-title primary-title>
                            <h3 class="display-2 teal--text lighten-4 font-weight-light">Login</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-form v-model="valid" ref="form">
                                <v-layout wrap align-center justify-center>
                                    <v-flex xs12>
                                        <v-text-field color="teal" v-model="email" :label="labels.email" required></v-text-field>
                                        <v-text-field color="teal" v-model="password" type="password" :label="labels.password" required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm8>    
                                        <v-checkbox v-model="rememberMe" color="teal">
                                            <div slot="label">{{ text.rememberMe }}</div>
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs12 sm4 >
                                        <a @click="$emit('navigateTo', '/forgot/password')"> {{ text.forgotPassword }} </a>
                                    </v-flex>
                                    <v-btn block large class="teal white--text" @click="submit" :disabled="!valid">
                                        Einloggen
                                    </v-btn>
                                </v-layout>
                            </v-form>
                            <v-alert color="error" icon="true" dismissible v-model="showAlert" transition="slide-y-reverse-transition">
                                    {{ alertText }}
                                </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <div> {{ text.createNewAccount }} <a @click="$emit('navigateTo', '/register')"> {{ text.createNewAccountHere }} </a></div>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'Login',
    data: () => ({
        valid: true,
        rememberMe: false,
        email: '',
        password: '',
        text: {
            title: 'Login',
            createNewAccount: 'Noch keinen Account? Registriere dich ',
            createNewAccountHere: 'hier.',
            rememberMe: 'Eingeloggt bleiben',
            forgotPassword: 'Passwort vergessen?'
        },
        labels: {
            email: 'E-mail',
            password: 'Passwort'
        },
        showAlert: false,
        alertText: ''
    }),
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                var self = this
                this.$axios.post('/login', {
                        email: this.email,
                        password: this.password
                    }).then(response => {
                        this.$store.commit('setUser', response.data)
                        this.$axios.get('/persons')
                        .then(response => {
                            if(response.data.length === undefined){
                                this.$store.commit('setPersons', [response.data])
                            }else{
                                this.$store.commit('setPersons', response.data)
                            }
                            this.$router.push('/courses')
                            this.$axios.get('/bookings')
                            .then(response => this.$store.commit('setBookings', response.data))
                        })
                        .catch(error => console.log(error))
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 403:
                                self.alertText = 'Benutzername oder Passwort falsch'
                                break
                            case 422:
                                self.alertText = 'Keine valide Email-Adresse'
                                break
                            case 449:
                                self.alertText = 'Bitte verifizieren Sie Ihre Email'
                                break
                            default:
                                self.alertText = error.response.data
                                break
                        }
                        self.showAlert = true
                    })
            }
        }
    }
}
</script>