<template>
    <v-container fill-height>
        <template v-if="loadingData">
            <v-progress-linear color="teal" :indeterminate="true"></v-progress-linear>
        </template>
        <template v-else>
            <!-- create & delete courses -->
            <ManageCourses v-if="$store.getters.isBeforePhase3"/>
            <!-- confirm courses & create invoices -->
            <AfterPhase3 v-else @refreshCourses="fetchData"/>
        </template>
    </v-container>
</template>
<script>
import ManageCourses from '@/components/admin/courses/ManageCourses.vue'
import AfterPhase3 from '@/components/admin/courses/AfterPhase3.vue'

export default {
    name: 'CoursesOverview',
    components: {
        ManageCourses,
        AfterPhase3
    },
    data: () => ({
        loadingData: true
    }),
    methods: {
        fetchData: function(){
            this.loadingData = true
            this.$axios.get('/courses')
            .then(response => this.$store.commit('setCourses', response.data))
            .catch(error => console.log(error))
            .finally(() => this.loadingData = false)
        }
    },
    created: function(){
        this.fetchData()
    },
    watch: {
        '$route': 'fetchData' 
    }
}
</script>

