import Vue from 'vue'
import Vuex from 'vuex'
import axiosImport from 'axios'
import helpers from './helpers'
const { detect } = require('detect-browser')

const browser = detect()

const axios = axiosImport.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
})

const Moment = require('moment')
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
moment.locale('de');

Vue.use(Vuex)

function setCoursePrices(course){
  course.costMember = course.costMember / 100
  course.costNonMember = course.costNonMember / 100
}

export default new Vuex.Store({
  strict: true,
  state: {
    user: undefined,
    ferienpass: {
      id: null,
      start: '',
      end: '',
      phase1: '',
      phase2: '',
      phase3: '',
      title: ''
    },
    bookings: [],
    ageGroups: [
      "1. KiGA",
      "2. KiGA",
      "1. Klasse",
      "2. Klasse",
      "3. Klasse",
      "4. Klasse",
      "5. Klasse",
      "6. Klasse",
      "Oberstufe",
      "alle"
    ],
    courses: [],
    snackBarText: '',
    showSnackbar: false,
    snackBarColor: 'warning',
    snackBarMultiLine: false,
    persons: []
  },
  mutations: {
    toggleExpand (state, courseId) {
      var course = state.courses.filter(course => course.id == courseId);
      course[0].expanded = !course[0].expanded;
    },
    setUser (state, user) {
      state.user = user;
    },
    setPersons (state, persons) {
      state.persons = persons
    },
    addPerson (state, newPerson){
      state.persons.push(newPerson)
    },
    updateAge(state, editedKid){
      state.persons.find(person => person.id == editedKid.id).age = editedKid.newAge
    },
    setBookings (state, bookings) {
      state.bookings = bookings
    },
    removeBooking (state, specificBooking){
      var bookings = state.bookings.find(booking => booking.personId == specificBooking.personId)
      var toDelete = bookings.courses.indexOf(specificBooking.courseId)
      if(toDelete > -1){
        bookings.courses.splice(toDelete, 1)
      }
    },
    setEvent(state, event){
      event.start = moment(event.start).local().format('YYYY-MM-DD HH:mm')
      event.end = moment(event.end).local().format('YYYY-MM-DD HH:mm')
      event.phase1 = moment(event.phase1).local().format('YYYY-MM-DD HH:mm')
      event.phase2 = moment(event.phase2).local().format('YYYY-MM-DD HH:mm')
      event.phase3 = moment(event.phase3).local().format('YYYY-MM-DD HH:mm')
      state.ferienpass = event
    },
    setCourses(state, courses){
      state.courses = courses
      state.courses.forEach(course => {
        course.expanded = false
        setCoursePrices(course)
        helpers.setCourseDateTime(course)
      });
    },
    addCourse(state, course){
      helpers.setCourseDateTime(course) 
      setCoursePrices(course)
      state.courses.push(course)
    },
    removeCourse(state, courseId){
      state.courses = state.courses.filter(course => course.id != courseId)
    },
    cancelCourse(state, courseId){
      state.courses.find(course => course.id === courseId).confirmed = false
    },
    confirmCourse(state, courseId){
      state.courses.find(course => course.id === courseId).confirmed = true
    },
    addToCourse(state, courseId){
      let c = state.courses.find(course => course.id == courseId)
      if(c){
        c.freeSeats--
      }
    },
    setSnackbarText (state, newText) {
      state.snackBarText = newText
    },
    toggleSnackbar (state) {
      state.showSnackbar = !state.showSnackbar
    },
    closeSnackbar(state) {
      state.showSnackbar = false
    },
    setSnackbarColor (state, newColor) {
      state.snackBarColor = newColor
    },
    setSnackbarMultiLine (state, multiLine) {
      state.setSnackbarMultiLine = multiLine
    }
  },
  actions: {
    logout () {
      axios.get('/logout')
      .catch(error => console.log(error))
      .finally(() => window.location.reload())
    }
  },
  getters: {
    isBrowserCompatible: () => {
      let compatibleBrowsers = ['chrome', 'firefox', 'edge', 'opera', 'safari']
      let isCompatible = (browser && compatibleBrowsers.includes(browser.name))
      return isCompatible
    },
    browser: () => {
      return browser
    },
    allCourses: state => {
      return state.courses
    },
    confirmedCourses: state => {
      return state.courses.filter(course => course.confirmed === true)
    },
    canceledCourses: state => {
      return state.courses.filter(course => course.confirmed === false)
    },
    isLoggedIn: state => {
      return (state.user && state.user.email != '')
    },
    isAdmin: (state, getters) => {
      return (getters.isLoggedIn && state.user.isAdmin)
    },
    isMember: (state) => {
      state.user.isMember
    },
    isConfirmedMember: (state) => {
      state.user.isConfirmedMember
    },
    isBeforePhase1: (state) => {
      return moment().isBefore(state.ferienpass.phase1)
    },
    isBeforePhase2: (state) => {
      return moment().isBefore(state.ferienpass.phase2)
    },
    isBeforePhase3: (state) => {
      return moment().isBefore(state.ferienpass.phase3)
    },
    isAfterPhase3: (state)=> {
      return moment().isSameOrAfter(state.ferienpass.phase3)
    },
    getBookablePersons: (state, getters) => (course) => {
      return state.persons.filter(person => {
        if(getters.isBeforePhase2){
          return (!getters.hasBooked(person.id, course.id) && getters.isInAgeRange(person.age, course) && getters.isFreeThen(person.id, course.id) && getters.getBookingsByPersonId(person.id).length <= 2) ? true : false
        } else {
          return (!getters.hasBooked(person.id, course.id) && getters.isInAgeRange(person.age, course) && getters.isFreeThen(person.id, course.id)) ? true : false
        }
      })
    },
    isFreeThen: (state, getters) => (personId, courseId) => {
      let bookingIds = getters.getBookingsByPersonId(personId)
      if(bookingIds.length == 0){
        return true
      }
      return (bookingIds.filter(bookingId => {
        let bookedCourse = getters.getCourseById(bookingId)
        let courseRange = moment.range(bookedCourse.start + " " + bookedCourse.startsAt, bookedCourse.end + " " + bookedCourse.endsAt)
        let toCheck = getters.getCourseById(courseId)
        let startDate = moment(toCheck.start+' '+toCheck.startsAt, 'YYYY-MM-DD HH:mm')
        let startOk = !courseRange.contains(startDate)
        let endDate = moment(toCheck.end +' '+toCheck.endsAt, 'YYYY-MM-DD HH:mm')
        let endOk = !courseRange.contains(endDate)
        return (!startOk || !endOk)
      }).length == 0) && (bookingIds.filter(bookingId => {
        let bookedCourse = getters.getCourseById(bookingId)
        let toCheck = getters.getCourseById(courseId)
        let courseRange = moment.range(toCheck.start + " " + toCheck.startsAt, toCheck.end + " " + toCheck.endsAt)
        let startDate = moment(bookedCourse.start+' '+bookedCourse.startsAt, 'YYYY-MM-DD HH:mm')
        let startOk = !courseRange.contains(startDate)
        let endDate = moment(bookedCourse.end +' '+bookedCourse.endsAt, 'YYYY-MM-DD HH:mm')
        let endOk = !courseRange.contains(endDate)
        return (!startOk || !endOk)
      }).length == 0)
    },
    getPersonByFirstname: (state) => (firstname) => {
        return state.persons.find(person => {
          return person.firstname === firstname
      })
    },
    getBookingsByPersonId: (state) => (personId) => {
      var bookings = state.bookings.find(booking => booking.personId === personId)
      return bookings ? bookings.courses : []
    },
    getCourseById: (state) => (courseId) => {
      return state.courses.find(course => course.id == courseId) || null
    },
    isInAgeRange: (state) => (age, course) => {
      var inRange = false;
      var courseFromAge = state.ageGroups.indexOf(course.minAge);
      var courseToAge = state.ageGroups.indexOf(course.maxAge);
      var ageToCheck = state.ageGroups.indexOf(age);

      if (courseFromAge <= ageToCheck && ageToCheck <= courseToAge)
        inRange = true;

      // special case: user selected all -> means no age restrictions
      if (ageToCheck == 9) {
        inRange = true;
      }

      // special case: course has no minimum age, but maybe a maximum age
      if (courseFromAge == -1) {
        // has no minimum age, but a maximum -> just check if filter is in range of course maximum age
        // both -1 -> no age restrictions set
        if (ageToCheck <= courseToAge || courseToAge == -1) {
          inRange = true;
        }
      }

      // special case: course has no maximum age, but maybe a minimum age
      if (courseToAge == -1) {
        if (courseFromAge <= ageToCheck || courseFromAge == -1) {
          inRange = true;
        }
      }

      return inRange;
    },
    hasBooked: (state) => (personId, courseId) => {
      var bookedCourse = state.bookings.find(booking => {
        return (booking.personId === personId && booking.courses.includes(courseId)) ? true : false
      })
      return bookedCourse ? true : false
    },
    getAgeGroupsForAccounts: (state) => {
      return state.ageGroups.filter(age => age !== "alle")
    },
    getDisplayDate: () => (startsAt, endsAt) => {
      let displayedDate = "";
      let startDateOK = (startsAt != "");
      let endDateOK = (endsAt != "");
      // TODO: implement TIME
      if(startDateOK){
          displayedDate += moment(startsAt).format('DD.MM.YYYY');
      }
      if(endDateOK){
          if(startDateOK && startsAt != endsAt){
              if(moment(startsAt).format('DD. MM') != moment(endsAt).format('DD. MM')){
                displayedDate += " - " + moment(endsAt).format('DD.MM.YYYY');
              }
          }
      }
      return displayedDate;
    },
  }
})


