<template>
    <v-layout wrap>
        <v-flex xs12 my-2>
            <h5 class="headline teal--text">Findet statt:</h5>
        </v-flex>
        <v-flex xs12>
            <v-data-table :headers="headers" :items="$store.getters.confirmedCourses" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>{{ formatCourseDateTime(item) }}</td>
                        <td>{{ item.maxSeats - item.freeSeats }}/{{ item.maxSeats }}</td>
                        <td>
                            <v-btn text icon color="teal" @click="showAdd(item.id)">
                                <v-icon>group_add</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn v-if="item.helpers" text icon color="teal" @click="showHelpers(item.id)">
                                <v-icon>assistant</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn text icon color="teal" @click="showParticipants(item.id)">
                                <v-icon>format_list_bulleted</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <!-- list of participants -->
        <v-dialog v-model="participantsDialog" scrollable persistent :overlay="false" max-width="500px" transition="dialog-transition">
             <v-card>
                <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                    <span>Teilnehmerliste</span>
                </v-card-title>
                <v-card-text class="subheading">
                    <v-progress-linear v-if="!participants" color="teal" :indeterminate="true"></v-progress-linear>
                    <v-list v-else ref="participantList">
                        <v-subheader>
                            {{ $store.getters.getCourseById(participants[0].CourseBooking.courseId).title }} - {{ formatCourseDateTime($store.getters.getCourseById(participants[0].CourseBooking.courseId)) }}
                        </v-subheader>
                        <template v-for="(participant, index) in participants">
                            <v-list-item :key="index" avatar>
                                <v-list-item-content>
                                    {{ participant.firstname }} {{ participant.lastname }}
                                </v-list-item-content>
                                <v-list-item-avatar>
                                    <v-btn text icon color="error" @click="showRemove(participant)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetParticipants()">
                        Schliessen
                    </v-btn>
                    <v-btn text icon color="teal" @click="print($store.getters.getCourseById(participants[0].CourseBooking.courseId))" :disabled="!participants">
                        <v-icon>print</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- add participant -->
        <v-dialog v-model="addDialog" scrollable persistent :overlay="false" max-width="500px" transition="dialog-transition">
             <v-card>
                <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                    <span>Teilnehmer hinzufügen</span>
                </v-card-title>
                <v-card-text class="subheading">
                    <v-window v-model="addStep">
                        <v-window-item :value="1">
                            <v-progress-linear v-if="!users" color="teal" :indeterminate="true"></v-progress-linear>
                            <v-autocomplete v-else :items="users" item-value="email" item-text="email" v-model="selectedUser" label="Account" color="teal"></v-autocomplete>
                        </v-window-item>
                        <v-window-item :value="2">
                            <v-progress-linear v-if="!persons" color="teal" :indeterminate="true"></v-progress-linear>
                            <v-autocomplete v-else :items="persons" v-model="selectedPerson" label="Person" color="teal">
                                <!-- HTML that describe how select should render items when the select is open -->
                                <template slot="item" slot-scope="data">
                                    {{ data.item.firstname }} <small><i>({{ data.item.age }})</i></small>
                                </template>
                                <!-- HTML that describe how select should render selected items -->
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.firstname }}
                                </template>
                            </v-autocomplete>
                        </v-window-item>
                    </v-window>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetAdd()">
                        Schliessen
                    </v-btn>
                    <v-btn v-if="addStep === 1" text color="teal" :disabled="!selectedUser" @click="showPersonSelection()">
                        Weiter
                    </v-btn>
                    <v-btn v-if="addStep === 2" text color="teal" :disabled="!selectedPerson" @click="addParticipant()">
                        Hinzufügen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <RemoveParticipantDialog :dialog="removeParticipantDialog" :toRemove="toRemove" @closeRemove="resetRemove" @removeSuccess="onRemoveSuccess"/>
        <HelperDialog :dialog="helpersDialog" :helpers="helpers" @closeHelpers="closeHelpers"/>
    </v-layout>
</template>

<script>
import jsPDF from 'jspdf'
import HelperDialog from '@/components/admin/rollout/HelperDialog.vue'
import RemoveParticipantDialog from '@/components/admin/rollout/RemoveParticipantDialog.vue'

export default {
    name: 'ConfirmedCourses',
    components: {
        HelperDialog,
        RemoveParticipantDialog
    },
    data: () => ({
        headers: [
            { text: 'Titel', value: 'title' },
            { text: 'Wann', value: 'start'},
            { text: '#Teilnehmer', value: 'participants'},
            { text: 'Hinzufügen' },
            { text: 'Helfer' },
            { text: 'Teilnehmerliste' },
        ],
        participantsDialog: false,
        participants: null,
        addDialog: false,
        addStep: 1,
        users: null,
        selectedUser: null,
        selectedPerson: null,
        persons: null,
        courseIdToAdd: null,
        helpersDialog: false,
        helpers: null,
        removeParticipantDialog: false,
        toRemove: null

    }),
    methods: {
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD. MMMM')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD. MMMM YYYY')
            } else {
                dateString += ' ' + this.$moment(course.start).format('YYYY')
            }
            dateString += ', ' + course.startsAt + ' - ' + course.endsAt + ' Uhr'
            return dateString
        },
        resetParticipants: function(){
            this.participantsDialog = false
            this.participants = null
        },
        showParticipants: function(courseId){
            this.getParticipants(courseId)
            this.participantsDialog = true
        },
        getParticipants: function(courseId){
            this.$axios.get('/courses/'+courseId+'/participants')
            .then(response => {
                this.participants = response.data
            })
            .catch(error => console.log(error))
        },
        print: function(course){
            let doc = new jsPDF('portrait', 'mm', 'a4')
            let kids = []
            doc.text(course.title + " - " + this.formatCourseDateTime(course), 20, 20)
            this.participants.forEach(person => {
                kids.push(person.firstname + " " + person.lastname + " (" + person.age+ ")")
            })
            doc.setFontSize(12)
            doc.text(kids, 20, 50, {
                lineHeightFactor: "1.5"
            })
            doc.save('Teilnehmer - '+course.title+'.pdf')
        },
        resetRemove: function(){
            this.removeParticipantDialog = false
            this.toRemove = null
        },
        showRemove: function(courseBooking){
            this.toRemove = courseBooking
            this.removeParticipantDialog = true
        },
        onRemoveSuccess: function(personId){
            this.$store.commit('closeSnackbar')
            this.$store.commit('setSnackbarColor', 'success')
            this.$store.commit('setSnackbarText', 'Buchung storniert')
            this.$store.commit('toggleSnackbar')
            this.participants = this.participants.filter(p => p.id != personId)
            this.resetRemove()
            this.$emit('refreshCourses')
        },
        resetAdd: function(){
            this.addDialog = false
            this.users = []
            this.persons = []
            this.selectedUser = null
            this.selectedPerson = null
            this.addStep = 1
            this.courseIdToAdd = null
        },
        showAdd: function(courseId){
            this.addDialog = true
            this.courseIdToAdd = courseId
            this.getUsers()
        },
        showPersonSelection: function(){
            this.getPersons(this.users.find(user => user.email === this.selectedUser).id)
            this.addStep++
        },
        getUsers: function(){
            this.$axios.get('/users')
            .then(response => {
                this.users = response.data
            })
            .catch(error => console.log(error))
        },
        getPersons: function(userId){
            this.$axios.get('/users/'+userId+'/persons')
            .then(response => this.persons = response.data)
            .catch(error => console.log(error))
        },
        addParticipant: function(){
            this.$axios.post('/courses/'+this.courseIdToAdd+'/addParticipant', {
                personId: this.selectedPerson.id
            })
            .then(() => this.$store.commit('addToCourse', this.courseIdToAdd))
            .catch(error => console.log(error))
            .finally(() => this.resetAdd())
        },
        showHelpers: function(courseId){
            this.helpersDialog = true
            this.$axios.get('/courses/'+courseId+'/helpers')
            .then(result => {
                this.helpers = result.data
            })
            .catch(error => console.log(error))
        },
        closeHelpers: function(){
            this.helpersDialog = false,
            this.helpers = null
        },
    }
}
</script>