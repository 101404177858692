<template>
    <v-container grid-list-xs fill-height mt-3>
        <v-layout wra align-center justify-center>
            <v-flex xs12 sm10 lg8>
                <v-window v-model="verificationStep">
                    <v-window-item :value="1">
                        <v-card>
                            <v-container fluid>
                                <v-card-title primary-title>
                                    <h3 class="display-2 teal--text lighten-4 font-weight-light">Persönliche Daten der erziehungsberechtigten Person</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="dataForm">
                                        <v-layout wrap align-center justify-center>
                                            <v-flex xs12 md5>
                                                <v-text-field color="teal" v-model="firstname" type="text"
                                                    label="Vorname" required :rules="[v => !!v || 'Bitte Vorname eingeben']"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md5 offset-md2>
                                                <v-text-field color="teal" v-model="lastname" type="text"
                                                    label="Nachname" required :rules="[v => !!v || 'Bitte Nachname eingeben']"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-flex xs12>
                                            <v-text-field color="teal" v-model="address" type="text" label="Adresse"
                                                append-icon="place" required :rules="[v => !!v || 'Bitte Adresse eingeben']"></v-text-field>
                                        </v-flex>
                                        <v-layout wrap align-center justify-center>
                                            <v-flex xs12 md5>
                                                <v-text-field color="teal" v-model="ZIP" type="text" label="PLZ"
                                                    required :rules="[v => !!v || 'Bitte PLZ eingeben']"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md5 offset-md2>
                                                <v-text-field color="teal" v-model="place" type="text" label="Ort"
                                                    required :rules="[v => !!v || 'Bitte Ort eingeben']"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12>
                                                 <v-text-field color="teal" v-model="emergencyNumber" type="text" label="Notfallnummer"
                                                    required :rules="[v => !!v || 'Bitte Notfallnummer eingeben. Wir werden Sie nur in dringenden Notfällen kontaktieren']"></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <!-- isMember -->
                                        <v-layout wrap align-center justify-space-between>
                                            <v-flex xs12 sm4>
                                                <v-checkbox v-model="isMember" color="teal">
                                                    <div slot="label">Ich bin Mitglied im Familienverein</div>
                                                </v-checkbox>
                                            </v-flex>
                                            <v-flex xs12 sm4>
                                                <v-checkbox v-model="notAMember" color="teal">
                                                    <div slot="label">Ich bin Nichtmitglied</div>
                                                </v-checkbox>
                                            </v-flex>
                                            <v-flex xs12 sm5>
                                                <v-btn :href="publicPath+'Beitrittserklärung.pdf'" target="_blank"
                                                text depressed color="teal darken-2">Ich möchte Mitglied werden*</v-btn>
                                            </v-flex>
                                            <p><i class="caption">*Und sende die Beitrittserklärung bis am {{ $moment($store.state.ferienpass.phase3).format('DD.MM.YYYY') }} an den Familienverein</i></p>
                                        </v-layout>
                                        <v-layout wrap align-center justify-center>
                                            <v-flex xs12>
                                                <v-text-field color="teal" v-model="email" label="Email"
                                                    append-icon="email" required :rules="[v => !!v || 'Email darf nicht leer sein']"></v-text-field>
                                                <v-text-field color="teal" v-model="password" type="password"
                                                    label="Passwort" append-icon="vpn_key" required :rules="[v => !!v || 'Passwort darf nicht leer sein']"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-checkbox v-model="acceptedAGB" color="teal" :rules="[v => !!v || 'Bitte akzeptieren Sie die AGBs']">
                                                    <div slot="label">Ich habe die <a href="/#/rules" target="_blank">Spielregeln</a> gründlich durchgelesen
                                                        und akzeptiere sie</div>
                                                </v-checkbox>
                                            </v-flex>
                                            <v-btn block large class="teal white--text" @click="validateDataForm">
                                                Weiter
                                            </v-btn>
                                        </v-layout>
                                    </v-form>
                                </v-card-text>
                            </v-container>
                        </v-card>
                    </v-window-item>
                    <v-window-item :value=2>
                        <v-card>
                            <v-container fluid>
                                <v-card-title primary-title>
                                    <h3 class="display-2 teal--text lighten-4 font-weight-light">Meine Kinder</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-form ref="kidsForm">
                                        <v-layout wrap align-center justify-center>
                                            <v-flex xs12 v-for="(kid, key) in kids" :key="key">
                                                <v-layout wrap>
                                                    <v-flex xs12 md3>
                                                         <v-text-field color="teal" v-model="kid.firstname" label="Vorname" required :rules="[v => !!v || 'Vorname darf nicht leer sein']"></v-text-field>
                                                    </v-flex>
                                                     <v-flex xs12 md3 offset-md1>
                                                         <v-text-field color="teal" v-model="kid.lastname" label="Nachname" required :rules="[v => !!v || 'Nachname darf nicht leer sein']"></v-text-field>
                                                    </v-flex>
                                                    <v-flex xs12 md3 offset-md1>
                                                        <v-select :items="$store.getters.getAgeGroupsForAccounts" v-model="kid.age" label="Klasse" color="teal" :rules="[v => !!v || 'In welche Klasse geht Ihr Kind?']"></v-select>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-layout align-center justify-center wrap>
                                                <v-flex xs1 md1>
                                                    <v-btn fab outlined small color="teal white--text"
                                                        @click="removeKid">
                                                        <v-icon>remove</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex xs1 md1>
                                                    <v-btn fab outlined small color="teal white--text" @click="addKid">
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                            <v-flex xs12>
                                                <v-layout wrap align-center justify-space-between>
                                                    <v-flex xs12 md3>
                                                        <v-btn block large class="teal white--text" @click="verificationStep--">
                                                            Zurück
                                                        </v-btn>
                                                    </v-flex>
                                                    <v-flex xs12 md3>
                                                        <v-btn block large class="teal white--text" @click="validateKidsForm">
                                                            Absenden
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-form>
                                </v-card-text>
                            </v-container>
                        </v-card>
                    </v-window-item>
                    <v-window-item :value=3>
                        <v-card>
                            <v-container fluid>
                                <v-card-title primary-title>
                                    <h3 class="display-2 teal--text lighten-4 font-weight-light">Verifizierung erfolgreich</h3>
                                </v-card-title>
                                <v-card-text>
                                     <v-layout wrap align-center justify-center>
                                         <v-flex xs12>
                                            Nach dem einloggen kannst Du den Ferienpass vollumfänglich nutzen.
                                         </v-flex>
                                        <v-flex xs12>
                                            <v-btn block large class="teal white--text" @click="$router.push('/login')">
                                                Login
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-container>
                        </v-card>
                    </v-window-item>
                </v-window>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: 'VerifyUser',
    data: () => ({
        verificationStep: 1,
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        address: '',
        place: '',
        ZIP: '',
        isMember: false,
        acceptedAGB: false,
        acceptedDataTerms: false,
        kids: [
            { firstname: '', lastname:'', age: '' }
        ],
        emergencyNumber: '',
        publicPath: process.env.BASE_URL
    }),
    computed: {
        token: function() {
            return this.$route.params.token
        },
        notAMember: {
            get: function () {
                return !this.isMember
            },
            set: function(value){
                this.isMember = !value
            }
        }
    },
    methods: {
        sendVerify: function() {
            var self = this
            this.$recaptcha('login').then(captchaToken => {
                self.$axios.post('/verify', {
                    email: self.email,
                    password: self.password,
                    firstname: self.firstname,
                    lastname: self.lastname,
                    address: self.address,
                    place: self.place,
                    ZIP: self.ZIP,
                    emergencyNumber: self.emergencyNumber,
                    isMember: self.isMember,
                    kids: self.kids,
                    token: self.token
                })
                .then(() => {
                    self.verificationStep++
                })
                .catch(error => {
                    self.$store.commit('setSnackbarMultiLine', true)
                    switch (error.response.status) {
                        case 422:
                            self.$store.commit('setSnackbarText', 'Konto mit der Email '+self.email+' existiert nicht')
                            break;
                        case 403:
                            self.$store.commit('setSnackbarText', 'Email oder Passwort falsch')
                            break;
                        default:
                            self.$store.commit('setSnackbarText', error.response.data)
                            break;
                    }
                    self.$store.commit('setSnackbarColor', 'error')    
                    self.$store.commit('toggleSnackbar')
                })
            }).catch(error => {
                // not a human
                self.$store.commit('setSnackbarMultiLine', true)
                self.$store.commit('setSnackbarText', 'You are not a human\n' + error)
                self.$store.commit('setSnackbarColor', 'error')    
                self.$store.commit('toggleSnackbar')
            })
        },
        addKid: function(){
            this.kids.push({ firstname: '', lastname: '', age: '' })
        },
        removeKid: function(){
            if(this.kids.length > 1){
                this.kids.pop()
            }
        },
        validateDataForm: function() {
            if(this.$refs.dataForm.validate()) {
                this.$store.commit('closeSnackbar')
                this.verificationStep++
            }
        },
        validateKidsForm: function() {
            if(this.$refs.kidsForm.validate()){
                this.$store.commit('closeSnackbar')
                this.sendVerify()
            }
        }
    }
}
</script>

<style>
    .grecaptcha-badge { visibility: hidden }
</style>


