<template>
    <v-container fill-height>
        <v-layout wrap align-center justify-center>
            <v-flex xs12 sm8 md7>
                <MyKids />
            </v-flex>
            <v-flex xs12 sm8 md7>
               <MyData />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import MyKids from '@/components/account/MyKids.vue'
import MyData from '@/components/account/MyData.vue'

export default {
    name: 'AccountOverview',
    components: {
        MyKids,
        MyData
    },
    data: () => ({
        
    })
}
</script>

