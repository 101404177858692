<template>
    <v-container grid-list-xs fill-height mt-3>
        <v-layout wrap >
            <v-flex xs12 sm6 offset-sm3>
                <v-card v-if='eMailSent' align-center justify-center>
                    <v-container fluid>
                        <v-card-title primary-title>
                            <h3 class="display-2 teal--text lighten-4 font-weight-light">Erfolgreich</h3>
                        </v-card-title>
                        <v-card-text>
                            Vielen Dank. Eine E-mail mit einem Bestätigungs-Link wurde versandt. Bitte überprüfen Sie Ihre Inbox.
                        </v-card-text>
                    </v-container>
                </v-card>
                <v-card v-else>
                    <v-container fluid>
                        <v-card-title primary-title>
                            <h3 class="display-2 teal--text lighten-4 font-weight-light">Passwort vergessen</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form">
                                <v-layout wrap align-center justify-center>
                                    <v-flex xs12>
                                        <v-text-field color="teal" v-model="email" :label="labels.email" required></v-text-field>
                                    </v-flex>
                                    <v-btn block large color="teal white--text" @click="submit" :disabled="!email">
                                        Neues Passwort anfordern
                                    </v-btn>
                                </v-layout>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <div> {{ text.createNewAccount }} <a @click="$emit('navigateTo', '/register')"> {{
                                    text.createNewAccountHere }} </a></div>
                        </v-card-actions>
                    </v-container>
                </v-card>
                <v-progress-linear v-if="loading" color="teal" :indeterminate="true"></v-progress-linear>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ForgotPassword',
    data() {
        return {
            rememberMe: false,
            email: '',
            loading: false,
            eMailSent: false,
            password: '',
            text: {
                title: 'Passwort vergessen',
                createNewAccount: 'Noch keinen Account? Registriere dich ',
                createNewAccountHere: 'hier.'
            },
            labels: {
                email: 'E-mail'
            },
            logo: {
            source:  require('@/assets/logos/tiny_logo.png'),
            tile: true
        }
        }
    },
    methods: {
        submit() {
            this.loading = true
            this.$axios.post('/reset', {
                email: this.email
            })
            .then(response => {
                this.eMailSent = true
            })
            .catch(error => {
                this.$store.commit('closeSnackbar')
                this.$store.commit('setSnackbarColor', 'error')
                this.$store.commit('setSnackbarMultiLine', true)
                if(error.response){
                    this.$store.commit('setSnackbarText', error.response.data)
                } else {
                    this.$store.commit('setSnackbarText', error)
                }
                this.$store.commit('toggleSnackbar')
            })
            .finally(() => this.loading = false)
        }
    }
}
</script>