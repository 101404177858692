<template>
    <v-container mt-4>
        <v-layout wrap>
            <v-flex xs12>
                <v-expansion-panels>
                    <v-expansion-panel v-for="(rule, index) in rules" :key="index">
                        <v-expansion-panel-header class="title">{{ rule.title }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="rules">
                                <v-card-text v-if="rule.isMultiLine">
                                    <p class="body-1" v-for="(line, index) in rule.text" :key="index"><span v-html="line"></span></p>
                                </v-card-text>  
                                <v-card-text v-else> <span v-html="rule.text"></span> </v-card-text>
                                <DateTimeline v-if="rule.hasTimeline" />
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import DateTimeline from '@/components/rules/DateTimeline.vue'

export default {
    name: 'Rules',
    components: {
        DateTimeline
    },
    computed: {
        rules: function () {
            return [{
                    title: 'Wer darf beim Ferienpass mitmachen?',
                    text: 'Der Ferienpass kann von allen Kindern ab dem 1. Kindergarten bis zur 9. Klasse besucht werden. ' +
                        'Die Kurse sind in Klassenstufen eingeteilt. Teilnahmeberechtigt sind alle Kinder aus Pfyn und Umgebung.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: this.$store.state.ferienpass.start ? 'Termine ' + this.$moment(this.$store.state.ferienpass.start).format('YYYY') : 'Termine',
                    text: this.ferienpassTimespan(),
                    hasTimeline: true,
                    isMultiLine: false
                },
                {
                    title: 'Anmeldung',
                    // 1 entry per line
                    text: [
                        'Die Anmeldung erfolgt ausschliesslich online und ist rechtsverbindlich.',
                        'Bevor ihr einen Ferienpasskurs buchen könnt, müsst ihr euch auf ferienpass-pfyn.ch registrieren. ' +
                        'Das Registrieren ist ab dem 02. Februar 2020 möglich!',
                        'Es müssen sich die Eltern bzw. die Erziehungsberechtigten registrieren. ' +
                        'Pro Familie ist nur ein LOGIN notwendig. ' +
                        'Bei der Registration könnt ihr dann die Kinder mit ihren persönlichen Daten einzeln unter "MEINE KINDER" eintragen.',
                        'Sobald ihr euch registriert habt, bekommt ihr ein Mail. Anhand des Links, welcher euch mit einem Mail gesendet wird, könnt ihr eure persönlichen Angaben und die der Kinder erfassen. ' +
                        'Es muss jedes Kind mit dem Namen, Vornamen und der Klasse erfasst werden. ' +
                        'Wenn die Registration abgeschlossen ist, könnt ihr euch einloggen und loslegen. (Falls ihr innert nützlicher Frist kein Mail erhaltet, schaut doch bitte im Spam-Ordner nach. Vielleicht ist es dort gelandet.)',
                        'Man muss sich nur einmal registrieren. Das LOGIN bleibt für die kommenden Jahre bestehen. '+
                        'Bitte kontrolliert jährlich eure Daten. Solltet ihr euer Passwort vergessen haben, könnt ihr unter dem Login ein Neues anfordern.',
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Mein Account',
                    text: 'Der Elternteil bzw. der Erziehungsberechtigte kann sich nach dem Login unter dem angelegten Benutzerkonto einen Überblick verschaffen.' +
                          'Dort findet ihr eine Übersicht mit allen gebuchten Kursen pro registriertes Kind. ' +
                          'Falls bei den persönlichen Angaben Änderungen erforderlich sind, kann man dies dort vornehmen.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Bezahlung',
                    text: [
                        '-	Via E-Banking:',
                        'Raiffeisenbank Seerücken, Pfyn<br/>IBAN: <b>CH75 8080 8002 5937 6352 5<br/>Familienverein Pfyn<br/>8505 Pfyn</b>',
                        '<span style="color:red">ZAHLUNGEN VIA POSTSCHALTER SIND NICHT MÖGLICH!!</span>',
                        '<b>Die Rechnung muss spätestens bis am 30. März 2024 beglichen sein. Bei Nichteinhaltung der Zahlungsfrist wird eine Mahngebühr von CHF 10.00 verrechnet!</b>',
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Kursbestätigung',
                    text: [
                        'Die per E-Mail zugestellte Kursbestätigungen enthalten sämtliche Detailangaben zu den gebuchten Kursen und gelten als verbindlich.',
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Teilnehmerzahl',
                    text: 'Für alle Angebote gibt es eine maximale Teilnehmerzahl. '+
                          'Diese richtet sich nach den Betreuungsmöglichkeiten und den Platzverhältnissen der Kursanbieter und kann nicht überschritten werden. '+
                          'Ausgebuchte Kurse sind gekennzeichnet und können nicht mehr gebucht werden.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Preise',
                    text: 'Die Kurskosten sind bei jedem Kurs individuell angegeben. ' +
                        'Nichtmitglieder zahlen pro Kurs einen Zuschlag. ' +
                        'Entscheidet ihr euch im Familienverein Pfyn Mitglied zu werden, gelangt ihr <a href="https://www.familienverein-pfyn.ch/wp-content/uploads/2011/04/Beitrittserklaerung-2.pdf">hier</a> zum Beitrittsformular, welches ihr gleich ausfüllen könnt. ' +
                        'Trifft das ausgefüllte Beitrittsformular bis zum Anmeldeschluss des Ferienpasses beim Vorstand ein, könnt ihr ab sofort von den Vergünstigungen profitieren.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Abmeldung',
                    text: 'Die Teilnahme ist Ehrensache. Wenn ihr nicht teilnehmt, wird das Kursgeld nicht rückerstattet. ' +
                        'Kurzfristige Abmeldungen müssen über das Ferienpasstelefon <a href="tel:+41775337085">077 / 533 70 85</a> gemeldet werden.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Absagen',
                    text: [
                        'Der Familienverein Pfyn behält sich das Recht vor Kurse abzusagen. Dies kann sein:<ul>' +
                        '<li>wenn die mindestens benötigte Teilnehmerzahl nicht erreicht wird.</li>' +
                        '<li>wenn Kursleiter/-innen bzw. Begleitpersonen ausfallen.</li>' +
                        '<li>wenn das Wetter eine Durchführung unmöglich macht.</li></ul>',
                        'Bei einer Absage benachrichtigen wir euch per E-Mail oder Telefon. Deshalb ist es wichtig, dass ihr beim Registrieren beides korrekt angebt.<br/>' +
                        'Für abgesagte Angebote kann kein Ersatz angeboten werden. Natürlich wird euch für abgesagte Kurse das Kursgeld zurückerstattet.'
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Betreuung / Verhaltensregeln',
                    text: [
                        'Die Betreuung der Kinder wird nur während den Kurszeiten gewährleistet. ' +
                        'Jedes Kind sollte rechtzeitig am Treffpunkt abgeholt werden, da mit Kursende die Aufsichtspflicht der Kursveranstalter endet. ' +
                        'Wir bitten euch um Verständnis, wenn das Ende der Kurszeiten nicht immer genau eingehalten werden können.',
                        'Wenn ein Kind eine Veranstaltung durch sein Verhalten wesentlich stört, kann es vom Veranstalter ausgeschlossen werden. ' +
                        'In diesem Fall wird die angegebene Kontaktperson kontaktiert.'
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Unfall- und Haftpflichtversicherung / Haftung',
                    text: 'Der Familienverein Pfyn und die Kursleiter übernehmen keinerlei Haftung für Schäden, die durch die teilnehmenden Kinder verursacht werden. ' +
                        'Versicherungen jeglicher Art sind Sache der Teilnehmer resp. deren Eltern oder Erziehungsberechtigte! ' +
                        'Wir empfehlen deshalb eine private Haftpflichtversicherung. Auch für Diebstähle wird keine Haftung übernommen.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Fahrgemeinschaften',
                    text: [
                        'Der Ferienpass bietet verschiedene Kurse an, die auch ausserhalb von Pfyn stattfinden. ' +
                        'Um euch als Eltern bzw. Erziehungsberechtigte zu entlasten, ist die Organisation von Fahrgemeinschaften sinnvoll. Bitte kümmert euch selber darum.<br/>' +
                        'Auf Wunsch kann bei der Kontaktperson eine Teilnehmerliste angefordert werden.',
                        'Bitte denkt daran: Kinder bis 12 Jahre oder unter 150 cm dürfen im Auto nur in Kindersitzen mitgeführt werden. '
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Helfer',
                    text: [
                        'Zusätzlich verlangen einige Kursveranstalter erwachsene Helfer. ' +
                        'Wenn ihr für euer Kind einen Kurs bucht, erscheint ein Feld „Helfer gesucht“. Bitte füllt dieses doch aus, wenn ihr den Kurs als Helfer unterstützen möchtet. ',
                        'Sollten wir zu wenig Helfer für die Kurse finden, in denen euer Kind angemeldet ist, werden wir uns telefonisch bei euch melden.',
                        'Herzlichen Dank allen Eltern für den Helfereinsatz.'

                    ],
                    hasTimeline: false,
                    isMultiLine: true
                },
                {
                    title: 'Fotos während Ferienpasskursen',
                    text: 'Bei einigen Kursen werden Fotos gemacht, welche auf der Homepage des Familienvereins Pfyn veröffentlicht werden. ' +
                        'Wer eine Veröffentlichung nicht wünscht, teilt uns dies bitte in schriftlicher Form mit. Ansonsten setzten wir das Einverständnis voraus.',
                    hasTimeline: false,
                    isMultiLine: false
                },
                {
                    title: 'Ferienpass Hotline',
                    text: [
                        'Für Fragen zur Buchung während der Anmeldephase erreicht ihr uns via Mail an <a href="mailto:ferienpass@familienverein-pfyn.ch">ferienpass@familienverein-pfyn.ch</a> ' + 
                        'oder telefonisch auf dem Ferienpasstelefon <a href="tel:+41775337085">077 / 533 70 85</a>.',
                        'Während der Ferienpasswoche sind wir vor Kursbeginn oder abends via Ferienpasstelefon unter der Nummer <a href="tel:+41775337085">077 / 533 70 85</a> '+
                        'zu erreichen oder jederzeit via Mail an <a href="mailto:ferienpass@familienverein-pfyn.ch">ferienpass@familienverein-pfyn.ch</a>.'
                    ],
                    hasTimeline: false,
                    isMultiLine: true
                }
            ]
        }
    },
    methods: {
        ferienpassTimespan: function () {
            if(this.$store.state.ferienpass.id){
                var text = 'Der Ferienpass findet vom '
                text += this.$moment(this.$store.state.ferienpass.start).format('DD.') + ' bis ' + this.$moment(this.$store.state.ferienpass.end).format('DD. MMMM YYYY')
                return text += ' statt.'
            } else {
                return 'Wir freuen uns auf nächste Mal!'
            }
        }
    }
}
</script>
