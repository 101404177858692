<template>
    <v-container fill-height>
        <v-layout wrap align-center>
            <v-flex xs12>
                <v-layout wrap justify-center>
                    <v-flex xs12 sm10 lg7>
                        <v-card>
                            <v-card-title primary-title>
                                <div>
                                    <h3 class="headline mb-0 teal--text">Neuer Ferienpass erstellen</h3>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="newEventForm">
                                    <span class="subheading grey--text">Daten:</span>
                                    <v-flex xs12>
                                        <v-text-field color="teal" v-model="title" type="text" label="Titel" required
                                            :rules="[v => !!v || 'Bitte Titel eingeben']"></v-text-field>
                                    </v-flex>
                                    <!-- from/to dates -->
                                    <v-layout wrap>
                                        <!-- from date picker -->
                                        <v-flex xs12 sm5>
                                            <v-dialog ref="fromDialog" v-model="fromDialogVisible"
                                                :return-value.sync="fromDate" persistent lazy full-width width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field slot="activator" v-model="fromDateFormated" label="Von:"
                                                        :rules="[v => !!v || 'Bitte Datum eintragen']"
                                                        prepend-icon="event" readonly color="teal" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="fromDate" scrollable color="teal lighten-2"
                                                    :min="$moment().subtract(1, 'days').format()">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="grey darken-1"
                                                        @click="fromDialogVisible = false">Cancel</v-btn>
                                                    <v-btn text color="teal" @click="$refs.fromDialog.save(fromDate)">OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-flex>
                                        <!-- to date picker -->
                                        <v-flex xs12 sm5 offset-sm2 mb-2>
                                            <v-dialog ref="toDialog" v-model="toDialogVisible"
                                                :return-value.sync="toDate" persistent lazy full-width width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field slot="activator" v-model="toDateFormated" label="Bis:"
                                                        :rules="[v => !!v || 'Bitte Datum eintragen']"
                                                        prepend-icon="event" readonly color="teal" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="toDate" scrollable color="teal lighten-2" :min="$moment(fromDate).format()">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="grey darken-1" @click="toDialogVisible = false">
                                                        Cancel</v-btn>
                                                    <v-btn text color="teal" @click="$refs.toDialog.save(toDate)">OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <!-- phase 1 -->
                                    <v-layout wrap justify-center mt-3>
                                        <v-flex xs12 sm5>
                                            <v-dialog ref="phase1Dialog" v-model="phase1DialogVisible"
                                                :return-value.sync="phase1" persistent lazy full-width width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field slot="activator" v-model="phase1DateFormated" :rules="[v => !!v || 'Bitte Datum eintragen']"
                                                        label="Phase 1:" prepend-icon="event" readonly color="teal" v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="phase1" scrollable color="teal lighten-2"
                                                    :max="$moment(fromDate).format()">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="grey darken-1"
                                                        @click="phase1DialogVisible = false">Cancel</v-btn>
                                                    <v-btn text color="teal" @click="$refs.phase1Dialog.save(phase1)">OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-flex>
                                    </v-layout>
                                    <!-- phase 2 -->
                                    <v-layout wrap justify-center>
                                        <v-flex xs12 sm5>
                                            <v-dialog ref="phase2Dialog" v-model="phase2DialogVisible"
                                                :return-value.sync="phase2" persistent lazy full-width width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field slot="activator" v-model="phase2DateFormated" :rules="[v => !!v || 'Bitte Datum eintragen']"
                                                        label="Phase 2:" prepend-icon="event" readonly color="teal" v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="phase2" scrollable color="teal lighten-2"
                                                    :min="$moment(phase1).format()"
                                                    :max="$moment(fromDate).format()">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="grey darken-1"
                                                        @click="phase2DialogVisible = false">Cancel</v-btn>
                                                    <v-btn text color="teal" @click="$refs.phase2Dialog.save(phase2)">OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-flex>
                                    </v-layout>
                                    <!-- phase 3 -->
                                    <v-layout wrap justify-center mb-2>
                                        <v-flex xs12 sm5>
                                            <v-dialog ref="phase3Dialog" v-model="phase3DialogVisible"
                                                :return-value.sync="phase3" persistent lazy full-width width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field slot="activator" v-model="phase3DateFormated" :rules="[v => !!v || 'Bitte Datum eintragen']"
                                                        label="Phase 3:" prepend-icon="event" readonly color="teal" v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="phase3" scrollable color="teal lighten-2"
                                                    :min="$moment(phase2).format()"
                                                    :max="$moment(fromDate).format()">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="grey darken-1"
                                                        @click="phase3DialogVisible = false">Cancel</v-btn>
                                                    <v-btn text color="teal" @click="$refs.phase3Dialog.save(phase3)">OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-flex>
                                    </v-layout>
                                    <v-btn block color="teal" outlined @click="createNewEvent">Ferienpass erstellen</v-btn>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'NewEvent',
    data() {
        return {
            title: '',
            fromDialogVisible: false,
            fromDate: '',
            toDialogVisible: false,
            toDate: '',
            phase1DialogVisible: false,
            phase1: '',
            phase2DialogVisible: false,
            phase2: '',
            phase3DialogVisible: false,
            phase3: '',
        }
    },
    computed: {
        fromDateFormated: function(){
            return this.formatDate(this.fromDate, 'L');
        },
        toDateFormated: function(){
            return this.formatDate(this.toDate, 'L');
        },
        phase1DateFormated: function(){
            return this.formatDate(this.phase1, 'L');
        },
        phase2DateFormated: function(){
            return this.formatDate(this.phase2, 'L');
        },
        phase3DateFormated: function(){
            return this.formatDate(this.phase3, 'L');
        },
    },
    methods: {
        formatDate: function(date, format){
            return date ? this.$moment(date).format(format) : ''
        },
        createNewEvent: function(){
            if(this.$refs.newEventForm.validate()){
                this.$store.commit('closeSnackbar')
                this.$axios.post('/event', {
                        title: this.title,
                        start: this.$moment(this.fromDate).utc().toDate(),
                        end: this.$moment(this.toDate).utc().toDate(),
                        phase1: this.$moment(this.phase1).utc().toDate(),
                        phase2: this.$moment(this.phase2).utc().toDate(),
                        phase3: this.$moment(this.phase3).utc().toDate()
                    }).then(response => {
                        this.$store.commit('setEvent', response.data)
                        this.$router.push('/admin/overview')
                    })
                    .catch(error => {
                        this.$store.commit('setSnackbarMultiLine', true)
                        this.$store.commit('setSnackbarText', error.response)
                        this.$store.commit('setSnackbarColor', 'error')
                    })
            }
        }
    }
}
</script>
