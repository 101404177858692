<template>
    <v-container grid-list-xs class="courses">
        <v-data-iterator :items="filteredCourses" content-tag="v-layout" :no-data-text="noDataText"
            :no-results-text="noResultsText" :search="search" :sort-by="pagination.sortBy" :sort-desc="pagination.descending" wrap>
            
            <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer 
                :pagination="pagination" 
                :options="options"
                @update:options="updateOptions"
                :items-per-page-text="rowsPerPageText"
                :items-per-page-options="rowsPerPageItems" />
            </template>

            <template slot="header">
                <v-dialog v-model="bookingDialogVisible" scrollable persistent max-width="500px"
                    transition="dialog-transition">
                    <!-- only render when visible to prevent courseToBook undefined but referenced durring render error -->
                    <v-card v-if="bookingDialogVisible">
                        <v-window v-model="bookingStep">
                            <v-card-title class="title teal--text font-weight-regular justify-space-between">
                                <span>{{ bookingDialogTitle }}</span>
                            </v-card-title>
                            <v-window-item :value="1">
                                <v-card-text>
                                    <v-form v-if="!apiCallOngoing" ref="personForm">
                                        <v-select :items="$store.getters.getBookablePersons(courseToBook)"
                                            v-model="selectedPerson" label="Wer möchte den Kurs besuchen?" color="teal"
                                            :rules="[v => !!v || 'Person auswählen']">
                                            <!-- HTML that describe how select should render items when the select is open -->
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.firstname }} <span v-if="$store.getters.isBeforePhase2">
                                                    <i><small>
                                                            ({{ $store.getters.getBookingsByPersonId(data.item.id).length }}
                                                            von 3 Kurse gebucht)</small></i>
                                                </span>
                                            </template>
                                            <!-- HTML that describe how select should render selected items -->
                                            <template slot="selection" slot-scope="data">
                                                {{ data.item.firstname }}
                                            </template>
                                            <template slot="no-data">
                                                <v-layout wrap align-center>
                                                    <v-flex xs10>
                                                        <span class="subheading">{{ bookingNoDataText }}</span>
                                                    </v-flex>
                                                </v-layout>
                                            </template>
                                        </v-select>
                                    </v-form>
                                    <v-progress-linear v-else color="teal" :indeterminate="true"></v-progress-linear>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text color="grey" @click="cancelBooking()">Abbrechen</v-btn>
                                    <v-btn text color="teal" @click="bookCourse(courseToBook.id)">Einschreiben</v-btn>
                                </v-card-actions>
                            </v-window-item>
                            <v-window-item :value="2">
                                <v-card-text>
                                    <!-- conditional rendering necessary to prevent minutes() is not a function error -> countdown is null before this step -->
                                    <v-flex xs12 v-if="bookingStep === 2">
                                        Ihr Platz bleibt {{ countdown.minutes() }} Minuten {{ countdown.seconds() }}
                                        Sekunden reserviert
                                    </v-flex>
                                    <v-form ref="confirmForm">
                                        <v-checkbox v-if="bookingStep === 2" color="teal" v-model="bookingConfirmed">
                                            <div slot="label">Hiermit melde ich <i>{{ selectedPerson.firstname }}</i>
                                                verbindlich für
                                                den Kurs <b><i>{{courseToBook.title}}</i></b> an.</div>
                                        </v-checkbox>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text color="grey" @click="cancelBooking()">Abbrechen</v-btn>
                                    <v-btn text color="teal" :disabled="!bookingConfirmed"
                                        @click="confirmBooking(courseToBook.id)">Bestätigen</v-btn>
                                </v-card-actions>
                            </v-window-item>
                            <v-window-item :value="3">
                                <v-card-text>
                                    Vielen Dank für deine Teilnahme. Dein Kurs wurde erfolgreich gebucht.
                                    <div v-if="courseToBook.helpers"><br>
                                        <p>Für die Durchführung vom Kurs <i>{{ courseToBook.title }}</i> suchen wir
                                            engagierte Helfer. Möchtest du helfen?
                                        </p>
                                        <div v-if="!helperAdded && !alreadyHelping">
                                            <v-checkbox label="Ja, ich möchte gerne helfen 😊" v-model="wantsToHelp"
                                                color="teal"></v-checkbox>
                                            <v-form ref="helperForm" v-if="wantsToHelp">
                                                <v-text-field v-model="helperName" name="helperName"
                                                    label="Vor- und Nachname" color="teal"></v-text-field>
                                                <v-text-field v-model="helperPhone" name="helperPhone"
                                                    label="Telefonnummer" color="teal"></v-text-field>
                                                <v-btn block color="teal white--text" @click="addHelper"
                                                    :disabled="helperName == '' || helperPhone == ''">Ich helfe gerne
                                                    mit</v-btn>
                                            </v-form>
                                        </div>
                                        <div v-else-if="alreadyHelping">
                                            Anscheinend hast du dich bereits als Helfer eingetragen. Vielen herzlichen
                                            Dank 😊 Sollte dies nicht der Fall sein, melde dich bitte beim
                                            Ferienpass-Team
                                        </div>
                                        <div v-else>
                                            Vielen Dank für deine Hilfe! 😊 Wir melden uns bei dir
                                        </div>
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text color="teal" @click="cancelBooking()"
                                        :disabled="!alreadyHelping && (!helperAdded && wantsToHelp)" v-on="on">Schliessen</v-btn>
                                </v-card-actions>
                            </v-window-item>
                        </v-window>
                    </v-card>
                </v-dialog>
                <!-- filter -->
                <v-flex xs12 lg8 offset-lg2 mt-3>
                    <!-- search -->
                    <v-layout align-center justify-center wrap fill-height>
                        <v-flex xs12 sm10>
                            <v-text-field color="teal" v-model="search" label="Suchen" append-icon="search" solo clearable>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout align-center justify-center wrap>
                        <!-- age selection -->
                        <v-flex xs8 sm4 lg4>
                            <v-layout justify-space-between column>
                                <!-- from age -->
                                <v-flex xs12 sm1>
                                    <v-layout align-center justify-center >
                                        <v-flex xs9 sm6>
                                            <v-combobox label="Klasse" color="teal" v-model="selectedAgeGroup"
                                                :items="$store.state.ageGroups" type="button"></v-combobox>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <!-- date selection -->
                        <v-flex xs8 sm2 lg2>
                            <!-- from date picker -->
                            <v-dialog ref="fromDialog" v-model="fromDialogVisible" :return-value.sync="fromDate"
                                persistent lazy full-width width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field color="teal" slot="activator" v-model="fromDateFormated" label="Von:"
                                        prepend-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker :allowed-dates="allowedDates" v-model="fromDate" scrollable
                                    :min="$store.state.ferienpass.start" :max="$store.state.ferienpass.end"
                                    color="teal lighten-2">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="fromDialogVisible = false">Cancel
                                    </v-btn>
                                    <v-btn text color="teal" @click="$refs.fromDialog.save(fromDate)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <v-flex xs8 sm2 lg2>
                            <!-- to date picker -->
                            <v-dialog ref="toDialog" v-model="toDialogVisible" :return-value.sync="toDate" persistent
                                lazy full-width width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field color="teal" slot="activator" v-model="toDateFormated" label="Bis:"
                                        prepend-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker :allowed-dates="allowedDates" v-model="toDate" scrollable
                                    :min="$store.state.ferienpass.start" :max="$store.state.ferienpass.end"
                                    color="teal lighten-2">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="toDialogVisible = false">Cancel
                                    </v-btn>
                                    <v-btn text color="teal" @click="$refs.toDialog.save(toDate)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <!-- order by  -->
                        <v-flex xs8 sm4 lg4>
                            <v-layout justify-space-between column>
                                <v-flex xs12 sm1>
                                    <v-layout align-center justify-center >
                                        <v-flex xs10 sm6>
                                            <v-combobox color="teal" label="Sortieren" v-model="sortBy"
                                                :items="sortingProperties" @change="setSortBy()" type="button">
                                            </v-combobox>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <!-- small view -->
                        <!-- <v-flex xs6 sm4 lg4>
                                <v-switch label="Listen-Ansicht" append-icon="list" color="teal lighten-2"
                                    v-model="smallView"></v-switch>
                            </v-flex> -->
                        <!-- clear filter -->
                        <v-flex xs12 sm1 lg1>
                            <v-layout align-center justify-center wrap>
                                <v-btn @click="resetFilters()" mt-1 small text icon color="teal">
                                    <v-icon medium>close</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </template>

            <!-- courses -->
            <!-- small view -->
            <v-flex xs12 v-if="smallView" slot="item" slot-scope="props" mt-2>
                <v-list>
                    <v-list-item @click="handleCardEvent({type: 'route', course: props.item})">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ props.item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon color="teal">shopping_cart</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-flex>
            <!-- normal view -->
            <v-flex v-else slot="item" slot-scope="props" my-16 align-center xs12 lg8 offset-lg2>
                <v-card @click="handleCardEvent({type: 'route', course: props.item})" hover>
                    <v-layout wrap>
                        <v-flex sm2 v-if="!isPhone">
                            <v-img :src="props.item.imgSrc" :lazy-src="props.item.imgSrc"></v-img>
                        </v-flex>
                        <v-flex xs12 sm10>
                            <v-img v-if="isPhone" max-height="420" :lazy-src="props.item.imgSrc"
                                :src="props.item.imgSrc"></v-img>
                            <v-layout wrap pl-3 pr-2>
                                <v-list>
                                    <v-flex xs12>
                                        <v-card-title primary-title>
                                            <h5 class="headline" v-bind:class="{'error--text': !props.item.confirmed && props.item.confirmed !== null}">{{ props.item.title }} {{props.item.confirmed === null || props.item.confirmed === true ? '' : '- Abgesagt'}}</h5>
                                        </v-card-title>
                                    </v-flex>
                                    <v-flex mb-2 xs12
                                        v-html="getDescriptionText(props.item.description, props.item.expanded)">
                                    </v-flex>
                                    <v-divider></v-divider>
                                    <!-- expandable details -->
                                    <template v-if="props.item.expanded">
                                        <v-layout mt-2 my-2 wrap>
                                            <v-flex xs12>
                                                <span class="font-weight-medium">Treffpunkt:</span>
                                                {{ props.item.venue }}
                                            </v-flex>
                                            <v-flex xs12>
                                                <span class="font-weight-medium">Besonderes:</span>
                                                {{ props.item.special }}
                                            </v-flex>
                                            <v-flex xs12>
                                                <span class="font-weight-medium">Leitung:</span>
                                                {{ props.item.leadership }}
                                            </v-flex>
                                        </v-layout>
                                        <v-divider></v-divider>
                                    </template>
                                    <!-- always visible details -->
                                    <v-layout mt-2 wrap>
                                        <v-flex>
                                            <v-chip outlined color="grey darken-1">
                                                <v-icon>accessibility_new</v-icon>
                                                {{ displayAge(props.item.minAge, props.item.maxAge) }}
                                            </v-chip>
                                        </v-flex>
                                        <v-flex>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip outlined color="grey darken-1" v-on="on">
                                                        <v-icon>attach_money</v-icon>
                                                        {{ props.item.costMember }} CHF / {{ props.item.costNonMember }}
                                                        CHF
                                                    </v-chip>
                                                </template>
                                                <span>Preis Mitglieder / Nichtmitglieder</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex>
                                            <v-chip outlined color="grey darken-1">
                                                <v-icon>event</v-icon>
                                                {{ $store.getters.getDisplayDate(props.item.start, props.item.end) }}
                                            </v-chip>
                                        </v-flex>
                                        <v-flex>
                                            <v-chip outlined color="grey darken-1">
                                                <v-icon>access_time</v-icon>
                                                {{ props.item.startsAt + " Uhr - " + props.item.endsAt + " Uhr"}}
                                            </v-chip>
                                        </v-flex>
                                        <v-flex>
                                            <v-chip outlined color="grey darken-1">
                                                <v-icon>location_on</v-icon>
                                                {{ props.item.location }}
                                            </v-chip>
                                        </v-flex>
                                        <v-flex>
                                            <v-chip outlined :class="freeSeatsStyle(props.item.freeSeats)">
                                                {{ (props.item.freeSeats >= 0 || props.item.freeSeats === 'unlimitiert') ? props.item.freeSeats : 0 }} freie
                                                Plätze
                                            </v-chip>
                                        </v-flex>
                                        <v-flex v-if="props.item.helpers">
                                            <v-chip outlined color="grey darken-1">
                                                Helfer gesucht
                                            </v-chip>
                                        </v-flex>
                                    </v-layout>
                                </v-list>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <template
                        v-if="bookingAllowed && (props.item.freeSeats > 0 || props.item.freeSeats === 'unlimitiert')">
                        <!-- book button -->
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn @click.stop="handleCardEvent({type: 'booking', course: props.item})" block
                                color="teal white--text">Einschreiben<v-icon right>shopping_cart</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </template>
                </v-card>
            </v-flex>
        </v-data-iterator>
    </v-container>
</template>

<script>
export default {
    name: 'Courses',
    data() {
        return {
            smallView: false,
            search: "",
            rowsPerPageItems: [5, 10, 50, ],
            rowsPerPageText: "Kurse pro Seite",
            pagination: {
                rowsPerPage: 5,
                sortBy: "title",
                descending: false
            },
            sortingProperties: [
                "alphabetisch",
                "freie Plätze",
                "Preis"
            ],
            sortBy: "alphabetisch",
            noDataText: "Keine Daten vorhanden.",
            noResultsText: "Keine Kurse gefunden. Bitte ändern Sie die Suche.",
            selectedAgeGroup: "alle",
            fromDialogVisible: false,
            fromDate: "",
            toDialogVisible: false,
            toDate: "",
            bookingDialogVisible: false,
            bookingStep: 1,
            selectedPerson: null,
            bookingConfirmed: false,
            courseToBook: null,
            countdownId: null,
            countdown: null,
            wantsToHelp: false,
            helperName: '',
            helperPhone: '',
            helperAdded: false,
            alreadyHelping: false,
            apiCallOngoing: false
        }
    },
    methods: {
        handleCardEvent: function(event){
            switch (event.type) {
                case 'route':
                    this.$store.commit('toggleExpand', event.course.id)
                    this.$forceUpdate();        
                    break;
                case 'booking':
                    if(this.$store.getters.isLoggedIn){
                        this.courseToBook = event.course
                        this.bookingDialogVisible = true
                    } else {
                        this.$router.push('/login')
                    }
                    break;
                default:
                    throw new Error('event type not defined in Courses.vue ' + event.type);
            }
        },
        displayAge: function(minimumAge, maximumAge){
            var displayedAge = "";
            // normal cases, both min and max set
            if(minimumAge !== "" && maximumAge !== "")
            {
                displayedAge += minimumAge;
                displayedAge += " - ";
                displayedAge += maximumAge;
            }
            else
            {   
                // one of both OR both are empty
                if(minimumAge !== "" && maximumAge == "") {
                    // has minimum and no maximum
                    displayedAge = "ab " + minimumAge;
                }
                else if(minimumAge == "" && maximumAge !== ""){
                    // no minimum but a maximum
                    displayedAge = "bis " + maximumAge;
                }
                else if(minimumAge == "" && maximumAge == "")
                {
                    // both empty -> no restrictions
                    displayedAge = "alle";
                }
            }
            return displayedAge;
        },
        allowedDates: function(dateFromCalendar){
            let dateToCheck = this.$moment(dateFromCalendar);
            let startDate = this.$moment(this.$store.state.ferienpass.start);
            let endDate = this.$moment(this.$store.state.ferienpass.end);
            let beforeStarDate = (startDate.isBefore(dateToCheck) || startDate.isSame(dateToCheck, 'day'));
            let beforeEndDate = (dateToCheck.isBefore(endDate) || dateToCheck.isSame(endDate, 'day'));
            if( beforeStarDate && beforeEndDate)
                return true;
            else
                return false;            
        },
        freeSeatsStyle: function(freeSeats){
            var red = false;
            var green= true;
            if(freeSeats <= 0){
                red = true
            }
            if(freeSeats === 'unlimitiert'){
                green = true
            }
            return {
                teal: green,
                "teal--text": green,
                "deep-orange": red,
                "deep-orange--text": red
            }
        },
        resetFilters: function(){
            this.selectedAgeGroup = "alle";
            this.fromDate = "";
            this.toDate = "";
            this.sortBy = "alphabetisch";
        },
        setSortBy: function(){
            switch (this.sortBy) {
                case "alphabetisch":
                    this.pagination.sortBy = "title"
                    this.pagination.descending = false
                    break;
                case "freie Plätze":
                    this.pagination.sortBy = "freeSeats"
                    this.pagination.descending = true
                    break;
                case "Preis": 
                    this.pagination.sortBy = "costNonMember"
                    this.pagination.descending = false
                    break;
            }
        },
        formatDate: function(date, format){
            return date ? this.$moment(date).format(format) : ''
        },
        isInDateRange: function (course){
            let startDate = this.$moment(course.start, "YYYY-MM-DD HH:mm");
            let endDate = this.$moment(course.end, "YYYY-MM-DD HH:mm");
            let fromDate = this.$moment(this.fromDate);
            let toDate = this.$moment(this.toDate);
            let isInRange = false;
            let fromDateSet = (this.fromDate != "");
            let toDateSet = (this.toDate != "");
            let startInRange = (fromDate.isBefore(startDate) || fromDate.isSame(startDate, 'day'));
            let endInRange = (endDate.isBefore(toDate) || endDate.isSame(toDate, 'day'));

            if(fromDateSet){
                if(toDateSet){
                    if(startInRange && endInRange){
                        isInRange = true;
                    }
                } else{
                    if(startInRange)
                        isInRange = true;
                }
            }
            else if(toDateSet){
                // from date is not set -> only test end date
                if(endInRange){
                    isInRange = true;
                }
            }
            if(this.fromDate == "" && this.toDate == ""){
                // both date filters are empty
                isInRange = true;
            }
            
            return isInRange;
        },
        getDescriptionText: function(originalText, isExpanded){
            if(isExpanded)
                return originalText
            else
                return originalText.length < 300 ? originalText + '<a class="tab">mehr</a>' : originalText.substring(0, 300) + '... <a>mehr</a>'
        },
        cancelBooking: function(){
            this.bookingDialogVisible = false
            clearInterval(this.countdownId)
            if(this.bookingStep === 2){
                // seat already booked, but not confirmed yet
                this.$axios.post('/course/'+this.courseToBook.id+'/cancel', {
                    personId: this.selectedPerson.id
                })
                .then(() => {
                    this.$axios.get('/courses')
                    .then(response => {
                        this.$store.commit('setCourses', response.data)
                        this.$axios.get('/bookings')
                        .then(response => this.$store.commit('setBookings', response.data))
                    })
                })
                .catch(error => console.log(error))
                .finally(() => this.resetBookingDialog())
            } else {
                this.resetBookingDialog()
            }
        }, 
        resetBookingDialog: function(){
            this.bookingDialogVisible = false
            this.countdown =  null
            this.countdownId =  null
            this.bookingStep = 1
            this.bookingConfirmed = false
            this.selectedPerson = null
            this.courseToBook = null
            this.wantsToHelp = false
            this.helperAdded = false
            this.alreadyHelping = false
        },
        bookCourse: function(courseId){
            if (this.$refs.personForm.validate()) {
                this.apiCallOngoing = true
                this.$axios.post('/course/' + courseId + '/book', {
                    personId: this.selectedPerson.id
                })
                .then(() => {
                    this.bookingStep++
                    this.initBookingCountdown()
                })
                .catch(error => console.log(error))
                .finally(() => this.apiCallOngoing = false)
            }
        },
        initBookingCountdown: function(){
            this.countdown = this.$moment.duration(5, 'minutes');
            this.countdownId = setInterval(() => {
                if(this.countdown.asSeconds() !== 0){
                    this.countdown.subtract(1, 'second'); 
                } else {
                   this.cancelBooking()
                }
            }, 1000);
        },
        confirmBooking: function(courseId) {
            clearInterval(this.countdownId)
            this.$axios.post('/course/' + courseId + '/confirm', {
                personId: this.selectedPerson.id
            })
            .then(() => {
                this.bookingStep++
                this.$axios.get('/courses')
                .then(response => {
                    this.$store.commit('setCourses', response.data)
                    this.$axios.get('/bookings')
                    .then(response => this.$store.commit('setBookings', response.data))
                })
            })
            .catch(error => console.log(error))
        },
        addHelper: function(){
            this.$axios.post('/courses/'+this.courseToBook.id+'/addHelper', {
                accountId: this.$store.state.user.id,
                helper: {
                    name: this.helperName,
                    phone: this.helperPhone
                }
            })
            .then(() => this.helperAdded = true)
            .catch(error => {
                console.log(error)
                this.alreadyHelping = true
            })
        }
    },
    computed: {
        isSmallDevice: function () {
            var size = this.$vuetify.breakpoint.name;
            if(size == 'xs' || size == 'sm')
                return true;  
            else
                return false; 
        },
        isPhone: function () {
            var size = this.$vuetify.breakpoint.name;
            if(size == 'xs')
                return true;  
            else
                return false; 
        },
        fromDateFormated: function(){
            return this.formatDate(this.fromDate, 'L');
        },
        toDateFormated: function(){
            return this.formatDate(this.toDate, 'L');
        },
        filteredCourses: function(){
            var filteredCourses = [];
            var courses = this.$store.getters.allCourses;
            courses.forEach(course => {
                if(this.$store.getters.isInAgeRange(this.selectedAgeGroup, course) && this.isInDateRange(course)) {
                    filteredCourses.push(course);
                }
            })
            return filteredCourses;
        },
        bookingAllowed: function(){
            return !this.$store.getters.isBeforePhase1 && this.$store.getters.isBeforePhase3
        },
        bookingDialogTitle: function(){
            switch (this.bookingStep) {
                case 1:
                    return 'Einschreiben: '
                case 2:
                    return 'Verbindlich bestätigen: '
                default:
                    return 'Einschreiben'
            }
        },
        bookingNoDataText: function(){
            if(this.$store.getters.isBeforePhase2){
                return "Sie haben entweder keine Kinder in dieser Klasse, ihr Kind ist bereits angemeldet, hat schon 3 Kurse gebucht oder besucht zu dieser Zeit bereits einen anderen Kurs"
            } else {
                return "Sie haben keine Kinder in dieser Klasse oder ihr Kind ist bereits angemeldet"
            } 
        }
    },
    watch: {
        smallView: function(){
            this.pagination.rowsPerPage = this.smallView ? 50 : 5;
        },
    },
    mounted: function(){
        this.$store.commit('closeSnackbar')
        this.$store.commit('setSnackbarMultiLine', true)
        this.$store.commit('setSnackbarColor', 'warning')
        if(!this.bookingAllowed && this.$store.getters.isBeforePhase1){
            this.$store.commit('setSnackbarText', 'Kurse können erst ab Phase 1 gebucht werden (siehe Spielregeln).')
            setTimeout(() => this.$store.commit('toggleSnackbar'), 500)
        }
        if(!this.bookingAllowed && this.$store.getters.isAfterPhase3){
            this.$store.commit('setSnackbarText', 'Anmeldefrist abgelaufen (siehe Spielregeln).')
            setTimeout(() => this.$store.commit('toggleSnackbar'), 500)
        }
    }
}
</script>

<style>
.tab{
    margin-left: 7px
}
</style>