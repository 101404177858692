<template>
    <Review v-if="!allCoursesReviewed"/>
    <Rollout v-on="$listeners" v-else />
</template>

<script>
import Review from '@/components/admin/review/Review.vue'
import Rollout from '@/components/admin/rollout/Rollout.vue'

export default {
    name: 'AfterPhase3',
    components: {
        Review,
        Rollout
    },
    computed: {
        allCoursesReviewed: function(){
            let notReviewd = this.$store.state.courses.filter(course => course.confirmed === null)
            return notReviewd.length === 0
        }
    }
}
</script>
