<template>
    <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="500px"
        transition="dialog-transition">
        <v-card>
            <v-card-title class="headline error--text font-weight-regular justify-space-between">
                <span>Wollen Sie diesen Teilnehmer wirklich entfernen?</span>
            </v-card-title>
            <v-card-text class="subheading">
                <v-progress-linear v-if="!toRemove" color="teal" :indeterminate="true"></v-progress-linear>
                <span v-else>Die Buchung von <i>{{ toRemove.firstname }} {{ toRemove.lastname }}</i> wird unwiderruflich storniert. Möchten Sie fortfahren?</span>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" text="text" @click="$emit('closeRemove')">
                    Abbrechen
                </v-btn>
                 <v-btn color="error" text="text" @click="remove()">
                    Stornieren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RemoveParticipantDialog',
    methods: {
        remove: function () {
            this.$axios.post('/course/' + this.toRemove.CourseBooking.courseId + '/cancel', {
                personId: this.toRemove.CourseBooking.personId,
            })
            .then(() => this.$emit('removeSuccess', this.toRemove.id))
            .catch(error => console.log(error))
        },
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        toRemove: {
            required: true,
        }
    },
}
</script>