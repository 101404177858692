<template>
    <v-layout wrap justify-center>
        <v-flex xs12 sm10 lg8>
            <v-card>
                <v-card-title primary-title>
                    <div>
                        <h3 class="headline mb-0 teal--text">Aktueller Ferienpass</h3>
                        <div></div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-layout column>
                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs8 lg4>
                                    <span class="body-2">Start:</span>
                                </v-flex>
                                <v-flex xs4>
                                    {{ $moment($store.state.ferienpass.start).format('DD. MMMM YYYY') }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 mb-2>
                            <v-layout>
                                <v-flex xs8 lg4>
                                    <span class="body-2">Ende:</span>
                                </v-flex>
                                <v-flex xs4>
                                    {{ $moment($store.state.ferienpass.end).format('DD. MMMM YYYY') }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-divider></v-divider>
                        <v-flex xs12 mt-2>
                            <v-layout>
                                <v-flex xs8 lg4>
                                    <span class="body-2">Phase 1:</span>
                                </v-flex>
                                <v-flex xs4>
                                    {{ $moment($store.state.ferienpass.phase1).format('DD. MMMM YYYY') }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs8 lg4>
                                    <span class="body-2">Phase 2:</span>
                                </v-flex>
                                <v-flex xs4>
                                    {{ $moment($store.state.ferienpass.phase2).format('DD. MMMM YYYY') }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs8 lg4>
                                    <span class="body-2">Phase 3:</span>
                                </v-flex>
                                <v-flex xs4>
                                    {{ $moment($store.state.ferienpass.phase3).format('DD. MMMM YYYY') }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="teal" to="/admin/courses">Kurse</v-btn>
                    <v-btn text color="error" @click="deleteEventVisible = true">Beenden</v-btn>
                    <v-dialog v-model="deleteEventVisible" persistent max-width="500px">
                        <v-card>
                            <v-window v-model="deleteEventStep">
                                <v-card-title
                                    class="headline error--text teal--text font-weight-regular justify-space-between">
                                    <span>{{ deleteEventTitle[deleteEventStep-1] }}</span>
                                </v-card-title>
                                <v-window-item :value="1">
                                    <v-card-text class="subheading">
                                        Möchten Sie wirklich den Event und sämtliche hinterlegten Kurse
                                        unwiderruflich
                                        löschen?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="grey" text="text" @click="cancelDeleteEvent()">
                                            Abbrechen
                                        </v-btn>
                                        <v-btn color="error" text="text"
                                            @click="confirmDeleteEvent($store.state.ferienpass.id)">
                                            Löschen
                                        </v-btn>
                                    </v-card-actions>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-card-text class="subheading">
                                        Um den Event unwiderruflich zu löschen, tippen Sie:
                                        <p><i><b>{{$store.state.ferienpass.title}}</b></i></p>
                                        <v-text-field color="error" :label="$store.state.ferienpass.title"
                                            v-model="deleteConfirmationText"></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text color="grey" @click="cancelDeleteEvent()">Abbrechen</v-btn>
                                        <v-btn text color="error" :disabled="!deleteEventConfirmed"
                                            @click="deleteEvent()">Bestätigen</v-btn>
                                    </v-card-actions>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-dialog>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'CurrentEvent',
    data() {
        return {
            deleteEventTitle: [
                "Event löschen",
                "Event unwiderruflich löschen?"
            ],
            deleteEventVisible: false,
            deleteEventStep: 1,
            deleteConfirmationText: '',
            eventIdToDelete: null,
        }
    },
    computed: {
        deleteEventConfirmed: function(){
            return this.deleteConfirmationText == this.$store.state.ferienpass.title
        },
    },
    methods: {
        confirmDeleteEvent: function(eventId){
            this.deleteEventStep++
            this.eventIdToDelete = eventId;

        },
        deleteEvent: function(){
            this.$store.commit('closeSnackbar')
            this.$axios.delete('/event/' + this.eventIdToDelete)
            .then(() => {
                this.$store.commit('setSnackbarText', 'Event erfolgreich gelöscht. Sämtliche Kurse wurden entfernt.')
                this.$store.commit('setSnackbarColor', 'success')
                this.$store.commit('setEvent', {})
                this.$store.commit('setCourses', [])
                this.deleteEventVisible = false
            })
            .catch(error => {
                this.$store.commit('setSnackbarMultiLine', true)
                this.$store.commit('setSnackbarText', error.response)
                this.$store.commit('setSnackbarColor', 'error')
            }).finally(() => {
                this.$store.commit('toggleSnackbar')
                this.cancelDeleteEvent()
            })
        },
        cancelDeleteEvent: function(){
            this.deleteEventVisible = false
            this.eventIdToDelete = null
            this.deleteEventConfirmed = false
            this.deleteEventStep = 1
            this.deleteConfirmationText = ''
        }
    }
}
</script>