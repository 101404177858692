<template>
    <v-layout wrap mt-2 v-if="loading">
        <v-flex xs12>
            <v-progress-linear color="teal" :indeterminate="true"></v-progress-linear>
        </v-flex>
        <v-flex xs12>
            Bitte haben Sie etwas Geduld. Diese Operation dauert pro Datensatz (Ferienpassbenutzer) ungefähr sechs Sekunden. 
        </v-flex>
    </v-layout>
    <v-layout v-else-if="$store.state.ferienpass.locked" wrap mt-2>
        <v-flex xs12>
            Rechnungen versenden in der Benutzerverwaltung
            <AfterEventConfirmed />
        </v-flex>
    </v-layout>
    <v-layout v-else wrap mt-2>
        <v-flex xs12>
            <ConfirmedCourses v-on="$listeners"/>
        </v-flex>
        <v-flex xs12 mt-4>
            <CanceledCourses />
        </v-flex>
        <v-flex xs12 mt-4>
            <v-btn block color="teal white--text" @click="confirmEventVisible = true">Bestätigen & Bearbeitung beenden</v-btn>
            <v-dialog v-model="confirmEventVisible" persistent max-width="500px">
                <v-card>
                    <v-window v-model="confirmEventStep">
                        <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                            <span>{{ confirmEventTitle[confirmEventStep-1] }}</span>
                        </v-card-title>
                        <v-window-item :value="1">
                            <v-card-text class="subheading">
                                Möchten Sie wirklich sämtliche Kurse offiziell bestätigen und die Bearbeitung vollständig beenden?
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="grey" text="text" @click="cancelConfirmEvent()">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="teal" text="text" @click="confirmEventStep++">
                                    Bestätigen
                                </v-btn>
                            </v-card-actions>
                        </v-window-item>
                        <v-window-item :value="2">
                            <v-card-text class="subheading">
                                Um den Event Unwiderruflich zu bestätigen, tippen Sie:
                                <p><i><b>{{$store.state.ferienpass.title}}</b></i></p>
                                <v-text-field color="teal" :label="$store.state.ferienpass.title"
                                    v-model="confirmEventText"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text color="grey" @click="cancelConfirmEvent()">Abbrechen</v-btn>
                                <v-btn text color="teal" :disabled="!confirmEventTextOk"
                                    @click="startEvent($store.state.ferienpass.id)">
                                    Bestätigen</v-btn>
                            </v-card-actions>
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import ConfirmedCourses from '@/components/admin/rollout/ConfirmedCourses.vue'
import CanceledCourses from '@/components/admin/rollout/CanceledCourses.vue'
import AfterEventConfirmed from '@/components/admin/rollout/AfterEventConfirmed.vue'

export default {
    name: 'Rollout',
    components: {
        ConfirmedCourses,
        CanceledCourses,
        AfterEventConfirmed
    },
    data: () => ({
        loading: false,
        confirmEventVisible: false,
        confirmEventStep: 1,
        confirmEventTitle: [
            'Kurse bestätigen & Bearbeitung beenden',
            'Unwiderruflich bestätigen'
        ],
        confirmEventText: ''
    }),
    methods: {
        startEvent: function(id){
            this.loading = true
            this.$axios.post('/event/confirm', {
                eventId: id
            })
            .then(result => {
                console.log(result)
                this.$store.commit('setEvent', result.data)
                this.cancelConfirmEvent()
            })
            .catch(error => console.log(error))
            .finally(() => this.loading = false)
        },
        cancelConfirmEvent: function () {
            this.confirmEventVisible = false
            this.confirmEventStep = 1
            this.confirmEventText = ''
        }
    },
    computed: {
        confirmEventTextOk: function() {
            return this.confirmEventText == this.$store.state.ferienpass.title
        }
    }
}
</script>