<template>
    <!-- helpers dialog -->
    <v-dialog v-model="dialog" scrollable persistent :overlay="false" max-width="500px"
        transition="dialog-transition">
        <v-card>
            <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                <span>Eingeschriebene Helfer</span>
            </v-card-title>
            <v-card-text class="subheading">
                <v-progress-linear v-if="!helpers" color="teal" :indeterminate="true"></v-progress-linear>
                <v-data-table v-else :headers="headers" :items="helpers" hide-default-footer disable-pagination class="elevation-1"
                    no-data-text="Keine Helfer eingeschrieben">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.name }}</td>
                            <td>{{ item.phone }}</td>
                            <td>
                                <v-btn v-if="item.accepted" text icon color="teal"
                                    @click="modifyHelper(item, false)">
                                    <v-icon>check_circle</v-icon>
                                </v-btn>
                                <v-btn v-else text icon color="error" @click="modifyHelper(item, true)">
                                    <v-icon>cancel</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" text="text" @click="$emit('closeHelpers')">
                    Schliessen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'HelperDialog',
    data: () => ({
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Telefon', value: 'phone'},
            { text: 'Angenommen', value: 'accepted'},
        ],
        modifyHelper: function(courseHelper, accept){
            this.$axios.post('/courses/'+courseHelper.courseId+'/helpers/modify', {
                courseHelperId: courseHelper.id,
                accept: accept
            })
            .then(() => courseHelper.accepted = accept)
            .catch(error => console.log(error))
        },
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        helpers: {
            required: true,            
        }
    },
}
</script>