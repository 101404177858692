<template>
    <v-layout wrap v-if="coursesToConfirm">
        <v-flex xs12 my-2>
            <h5 class="headline teal--text">Durchführbare Kurse:</h5>
        </v-flex>
        <v-flex xs12>
            <template v-if="loadingData">
                <v-progress-linear color="teal" :indeterminate="true"></v-progress-linear>
            </template>
            <v-data-table v-else :headers="headers" :items="coursesToConfirm" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>{{ formatCourseDateTime(item) }}</td>
                        <td>{{ item.maxSeats - item.freeSeats }}/{{ item.maxSeats }}</td>
                        <td>
                            <v-btn text icon color="teal" @click="confirm(item.id)">
                                <v-icon>check_circle</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn text icon color="error" @click="cancel(item.id)">
                                <v-icon>cancel</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
import helpers from '@/helpers'

export default {
    name: 'CoursesToConfirm',
    data: () => ({
        coursesToConfirm: null,
        loadingData: true,
        headers: [
            { text: 'Titel', value: 'title' },
            { text: 'Wann', value: 'start'},
            { text: '#Teilnehmer', value: 'participants'},
            { text: 'Bestätigen', value: 'confirm'},
            { text: 'Ablehnen', value: 'reject'},
        ]
    }),
    methods: {
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD.MM.')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD.MM.YYYY')
            } else {
                dateString += this.$moment(course.start).format('YYYY')
            }
            dateString += ', ' + course.startsAt + ' - ' + course.endsAt + ' Uhr'
            return dateString
        },
        fetchData: function(){
            this.loadingData = true
            this.$axios.get('/courses/toConfirm')
            .then(result => {
                this.coursesToConfirm = result.data
                this.coursesToConfirm.forEach(course => helpers.setCourseDateTime(course))
            })
            .catch(error => console.log(error))
            .finally(() => this.loadingData = false)
        },
        confirm: function(courseId){
            this.modifyCourse(courseId, 'confirm')
        },
        cancel: function(courseId){
            this.modifyCourse(courseId, 'cancel')
        },
        modifyCourse: function(courseId, action){
            this.$axios.post('/courses/'+courseId+'/'+action)
            .then(() => {
                this.coursesToConfirm = this.coursesToConfirm.filter(course => course.id != courseId)
                if(action === 'confirm'){
                    this.$store.commit('confirmCourse', courseId)
                } else if(action === 'cancel'){
                    this.$store.commit('cancelCourse', courseId)
                }
            })
            .catch(error => console.log(error))
        }
    },
    created: function(){
        this.fetchData()
    },
    watch: {
        '$route': 'fetchData' 
    }
}
</script>