<template>
    <v-container>
       <v-timeline dense clipped>
            <v-timeline-item v-for="(datePoint, index) in datePoints" :key="index" class="mb-3" :color="isBetweenNowAndNext(index, datePoint.date) ? 'teal' : 'grey'"
                icon-color="grey lighten-2" small filled>
                <v-layout justify-space-between>
                    <v-flex xs7 class="body-1">  {{ datePoint.text }} </v-flex>
                    <v-flex xs5 text-xs-right class="body-2">Ab {{ getStartDate(datePoint.date) }} </v-flex>
                </v-layout>
            </v-timeline-item>
        </v-timeline>
    </v-container>
</template>

<script>
export default {
    name: 'DateTimeline',
    data: function(){
        return{
            
        }
    },
    methods: {
        getStartDate: function(dateTime){
            return this.$moment(dateTime).format('dddd, DD.MMMM.YYYY');
        },
        isBetweenNowAndNext: function(currentIndex, phaseStart){
            phaseStart = new Date(phaseStart);
            var today = new Date();
            var nextDate;
            var nextIndex = currentIndex + 1;
            var hasNext = (nextIndex < this.datePoints.length);

            var isFirst = (currentIndex === 0);
            var isBeforePhaseStart = (phaseStart > today);
            if(isFirst && isBeforePhaseStart)
            {
                // one point should always be green
                return true;
            }
            else
            {
                if(hasNext)
                {
                    nextDate = new Date(this.datePoints[nextIndex].date);
                    return this.$moment(today).isBetween(phaseStart, nextDate);
                }
                else
                { 
                    // we are at the last element. just check if we are already over the given date
                    if(phaseStart < today)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
            }
        }
    },
    computed: {
        datePoints: function () {
            return [{
                    title: 'Registration',
                    date: new Date('2020-02-02T00:00:00'),
                    text: 'Einmalige Registration online unter www.ferienpass-pfyn.ch möglich.'
                },
                {
                    title: 'Phase 1',
                    date: this.$store.state.ferienpass.phase1,
                    text: 'Online-Anmeldung: Pro Kind können max. 3 Kurse gebucht werden.'
                },
                {
                    title: 'Phase 2',
                    date: this.$store.state.ferienpass.phase2,
                    text: 'Online-Anmeldung: Pro Kind können weitere Kurse gebucht werden.'
                },
                {
                    title: 'Online Anmeldeschluss',
                    date: this.$store.state.ferienpass.phase3,
                    text: 'Online-Anmeldeschluss: Es können keine Kurse mehr gebucht werden.'
                },
            ]
        }
    }
}
</script>
