<template>
    <h1>{{text}}</h1>
</template>

<script>
export default {
    name: 'NotFound',
    data: () => ({
        text: '404 not found'
    })
}
</script>

