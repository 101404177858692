import 'babel-polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
})
Vue.prototype.$axios = axiosInstance

Vue.use(VueReCaptcha, { siteKey: '6LdXQaQUAAAAAJh3XSOK0T-gJU_cnc2BOpPHg0b8' })

const Moment = require('moment')
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
require('moment/locale/de')
moment.locale('de');

Vue.use(require('vue-moment'), {
    moment
})

new Vue({
  vuetify,
  render: h => h(App),
  store,
  router
}).$mount('#app')
