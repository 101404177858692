import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueRouter from 'vue-router';
import { VLayout } from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
    components: { VLayout },
    lang: {
      locales: { de },
      current: 'de'
    }
});

Vue.use(VueRouter);