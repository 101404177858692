import VueRouter from 'vue-router'
import LandingPage from '@/components/landing/LandingPage.vue'
import Login from '@/components/login/Login.vue'
import ForgotPassword from '@/components/login/ForgotPassword.vue'
import ResetPassword from '@/components/login/ResetPassword.vue'
import Register from '@/components/register/Register.vue'
import VerifyUser from '@/components/register/VerifyUser.vue'
import Courses from '@/components/courses/Courses.vue'
import Rules from '@/components/rules/Rules.vue'
import Team from '@/components/team/Team.vue'
import NotFound from '@/components/NotFound.vue'
import AdminOverview from '@/components/admin/Overview.vue'
import NewEvent from '@/components/admin/NewEvent.vue'
import CoursesOverview from '@/components/admin/courses/Courses.vue'
import AccountOverview from '@/components/account/Overview.vue'
import UnconfirmedUsers from '@/components/admin/users/UnconfirmedUsers.vue'
import DetailedOverview from '@/components/admin/users/DetailedOverview.vue'
import MyCourses from '@/components/account/MyCourses.vue'
import Invoices from '@/components/account/Invoices.vue'

import store from './store'

const VERIFICATION_TOKEN_LENGTH = 32 // todo: put this in .env

const isAdmin = (to, from, next) => {
  if(store.state.user && store.state.user.isAdmin)
    next()
  else 
    next('/404')
}

const isLoggedIn = (to, from, next) => {
  if(store.state.user){
    next()
  } else {
    next('/404')
  }
}

const routes = [
    { path: '/', component: LandingPage },
    { path: '/login', component: Login },
    { path: '/forgot/password', component: ForgotPassword },
    { path: '/reset/password/:token', component: ResetPassword },
    { path: '/register', component: Register },
    { path: '/verify/:token', component: VerifyUser,
      beforeEnter: (to, from, next) => {
        if(to.params.token.length == VERIFICATION_TOKEN_LENGTH)
          next()
        else
          next('/404')
      }
    },
    { path: '/admin/overview', component: AdminOverview, beforeEnter: isAdmin },
    { path: '/admin/new/event', component: NewEvent, beforeEnter: isAdmin },
    { path: '/admin/courses', component: CoursesOverview, beforeEnter: isAdmin },
    { path: '/admin/users/unconfirmed', component: UnconfirmedUsers, beforeEnter: isAdmin},
    { path: '/admin/users/detailed', component: DetailedOverview, beforeEnter: isAdmin},
    { path: '/my/account', component: AccountOverview, name: 'myAccount', beforeEnter: isLoggedIn},
    { path: '/my/invoices', component: Invoices, name: 'myInvoices', beforeEnter: isLoggedIn},
    { path: '/my/courses', component: MyCourses, name: 'myCourses', beforeEnter: isLoggedIn},
    { path: '/courses', component: Courses },
    { path: '/rules', component: Rules},
    { path: '/team', component: Team},
    { path: '*', component: NotFound, name: '404' }
  ]
  
export default new VueRouter({
    routes
})