<template>
    <v-card>
        <v-card-title primary-title>
            <div>
                <h3 class="headline teal--text">Meine Kinder</h3>
            </div>
        </v-card-title>
        <v-card-text>
            <v-flex xs12 v-for="kid in $store.state.persons" :key="kid.id" mb-1>
                {{ kid.firstname}} <span class="font-italic font-weight-light">{{ kid.age }}</span> <v-btn v-if="canEdit" @click="edit(kid.id)" text small icon color="teal">
                    <v-icon>edit</v-icon>
                </v-btn>
            </v-flex>
            <v-divider></v-divider>
            <v-layout wrap align-center justify-center>
                <v-flex mt-3 xs2>
                    <v-btn @click="addNewKid()" outlined color="teal" fab small dark>
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-bottom-sheet v-model="showEditKid">
                <v-card v-if="editedKid">
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline teal--text">{{$store.state.persons.find(person => editedKid.id == person.id).firstname}} editieren</h3>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-layout wrap justify-center>
                            <v-flex xs12 sm6>
                                <v-select :items="$store.getters.getAgeGroupsForAccounts" v-model="editedKid.newAge" label="neue Klasse" color="teal" :rules="[v => !!v || 'In welche Klasse geht Ihr Kind?']"></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-btn block color="teal white--text" @click="sendChanges()" :disabled="!editedKid.newAge">Absenden</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-bottom-sheet>
            <v-bottom-sheet v-model="showAddNewKid">
                <v-card v-if="newKid">
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline teal--text">Neues Kind erfassen</h3>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-layout wrap justify-center>
                            <v-layout wrap>
                                <v-flex xs12 sm3>
                                        <v-text-field color="teal" v-model="newKid.firstname" label="Vorname" required :rules="[v => !!v || 'Vorname darf nicht leer sein']"></v-text-field>
                                </v-flex>
                                    <v-flex xs12 sm3 offset-sm1>
                                        <v-text-field color="teal" v-model="newKid.lastname" label="Nachname" required :rules="[v => !!v || 'Nachname darf nicht leer sein']"></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm3 offset-sm1>
                                    <v-select :items="$store.getters.getAgeGroupsForAccounts" v-model="newKid.age" label="Klasse" color="teal" :rules="[v => !!v || 'In welche Klasse geht Ihr Kind?']"></v-select>
                                </v-flex>
                            </v-layout>
                            <v-flex xs12>
                                <v-btn block color="teal white--text" @click="createNewKid()" :disabled="!(newKid.age && newKid.firstname && newKid.lastname)">Absenden</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-bottom-sheet>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'MyKids',
    data: () => ({
        editedKid: null,
        showEditKid: false,
        showAddNewKid: false,
        newKid: null
    }),
    computed: {
        canEdit: function(){
            let today = new Date()
            if(this.$store.state.ferienpass.phase3){
                return !this.$moment(today).isSameOrAfter(this.$store.state.ferienpass.phase3) //!this.$moment(today).isBetween(this.$store.state.ferienpass.phase3, this.$store.state.ferienpass.phase3)
            } else {
                return true
            }
        }
    },
    methods: {
        resetEditedKid: function () {
            this.showEditKid = false
            this.editedKid = null
        },
        edit: function(kidId){
            this.editedKid = {
                id: kidId
            }
            this.showEditKid = true;
        },
        sendChanges: function(){
            if(this.editedKid.newAge){
                this.$axios.put('/person/'+this.editedKid.id+'/age', {
                    newAge: this.editedKid.newAge
                })
                .then(() => {
                    this.$store.commit('updateAge', this.editedKid)
                })
                .catch(error => console.log(error.message))
                .finally(() => this.resetEditedKid())
            } else{
                this.resetEditedKid()
            }
        },
        resetNewKid: function(){
            this.showAddNewKid = false
            this.newKid = null
        },
        addNewKid: function(){
            this.newKid = {
                firstname: '',
                lastname: '',
                age: ''
            }
            this.showAddNewKid = true
        },
        createNewKid: function(){
            if(this.newKid){
                this.$axios.post('/persons/new', {
                    accountId: this.$store.state.user.id,
                    newKid: this.newKid
                })
                .then(response => {
                    this.$store.commit('addPerson', response.data)
                })
                .catch(error => console.log(error))
                .finally(() => this.resetNewKid())
            } else{
                this.resetNewKid()
            }
        }
    }
}
</script>

<style>

</style>