<template>
    <v-container fluid>
        <v-layout wrap justify-center align-center>
            <v-flex xs12 sm8 md4>
                <v-card v-if="!passwordChanged">
                    <v-card-title primary-title>
                        <h3 class="display-2 teal--text lighten-4 font-weight-light">Neues Passwort setzen</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="resetPasswordForm">
                            <v-text-field label="E-mail" v-model="email" color="teal" type="email" required>
                            </v-text-field>
                            <v-text-field label="Neues Passwort" v-model="password" color="teal" type="password" required>
                            </v-text-field>
                            <v-text-field label="Passwort wiederholen" v-model="passwordRepeated" color="teal"
                                type="password" required></v-text-field>
                            <v-btn block large class="teal white--text" @click="changePassword()"
                                :disabled="!(email && password && passwordRepeated && password == passwordRepeated)">
                                Passwort ändern
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-card v-else>
                    <v-card-title primary-title>
                        <h3 class="display-2 teal--text lighten-4 font-weight-light">Passwort erfolgreich geändert</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-btn block class="teal white--text" to="/login">
                            Zum Login
                        </v-btn>
                    </v-card-text>
                </v-card>
                <v-progress-linear v-if="loading" color="teal" :indeterminate="true"></v-progress-linear>
            </v-flex>
        </v-layout>
    </v-container>
    
</template>

<script>
export default {
    name: 'ResetPassword',
    data: () => ({
        email: '',
        password: '',
        passwordRepeated: '',
        loading: false,
        passwordChanged: false
    }),
    computed: {
        token: function() {
            return this.$route.params.token
        }
    },
    methods: {
        changePassword: function(){
            this.loading = true
            this.$store.commit('closeSnackbar')
            this.$store.commit('setSnackbarMultiLine', true)
            this.$axios.post('/reset/password', {
                email: this.email,
                newPassword: this.password,
                token: this.token
            })
            .then(() => {
                this.$store.commit('setSnackbarColor', 'success')
                this.$store.commit('setSnackbarText', 'Password erfolgreich geändert')
                this.passwordChanged = true
            })
            .catch(error => {
                this.$store.commit('setSnackbarColor', 'error')
                if(error.response){
                    this.$store.commit('setSnackbarText', error.response.data)
                } else {
                    this.$store.commit('setSnackbarText', error)
                }
            })
            .finally(() => {
                this.loading = false
                this.$store.commit('toggleSnackbar')
            })
        }
    }
}
</script>

<style>

</style>