<template>
  <v-layout align-center justify-center fill-height>
    <v-flex xs12>
      <v-card class="mx-auto" max-width="800">
        <v-img :aspect-ratio="16/9" :lazy-src="teamImage" :src="teamImage"></v-img>
        <v-card-text class="pt-7" style="position: relative;">
          <v-btn absolute class="white--text" color="teal" fab large right top href="mailto:ferienpass@familienverein-pfyn.ch">
            <v-icon>mail</v-icon>
          </v-btn>
          <div class="font-weight-light grey--text title mb-2">unsere treibende Kraft</div>
          <h4 class="display-1 font-weight-light  mb-4">Familienverein Pfyn</h4>
          <p class="subheading" v-for="(paragraph, index) in teamDescription" :key="index" v-html="paragraph">
            {{ paragraph }}
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Team',
  components: {
  },
  data: () => ({
    teamImage: require('@/assets/team_wiese.jpg'),
    teamDescription: [
      'Der Familienverein Pfyn organisiert einmal jährlich einen Ferienpass. Dieser findet in der Regel in den Frühlingsferien statt.',
      'Das Ferienpass-Team besteht aus den Verantwortlichen des Vorstandes sowie freiwilligen Vereinsmitgliedern.',
      'Jeweils im Spätsommer schwärmen die Teammitglieder aus, um mit viel Freude und Enthusiasmus neue Kurse und Erlebnisse für unsere Kids zu organisieren.',
      'Ganz herzlich bedanken möchten wir uns auch bei allen KursleiterInnen und Betreuungspersonen, die wir gerne zu unserem erweiterten Team zählen. Ohne diese "Heinzelmännchen" wäre unser Projekt lediglich eine Seifenblase.',
      'Möchten Sie in unserem Team mitarbeiten oder haben Sie eine Idee für einen neuen Kurs? Dann melden Sie sich bitte bei Jeannine Greutmann (<a href="mailto:ferienpass@familienverein-pfyn.ch">ferienpass@familienverein-pfyn.ch</a> oder 052 366 38 60).'
    ]
  })
}
</script>

 