<template>
    <v-layout wrap align-center justify-center>
            <v-flex xs12 my-2>
                <h5 class="headline teal--text">Neumitglieder:</h5>
            </v-flex>
            <v-flex xs12 sm10 lg10>
                <v-progress-linear v-if="!users" color="teal" :indeterminate="true"></v-progress-linear>
                <v-data-table v-else :headers="headers" :items="newMembers" hide-default-footer disable-pagination class="elevation-1">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.email }}</td>
                            <td>{{ item.firstname }}</td>
                            <td>{{ item.lastname }}</td>
                            <td><v-icon :class="{ 'green--text': item.isMember, 'red--text': !item.isMember }">{{ item.isMember ? 'check' : 'close' }}</v-icon></td>
                            <td>
                                <v-btn text icon color="teal" @click="confirm(item.id)">
                                    <v-icon>check_circle</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn text icon color="error" @click="cancel(item.id)">
                                    <v-icon>cancel</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
</template>

<script>
export default {
    name: 'UnconfirmedUsers',
    data: () => ({
        users: null,
        headers: [
            { text: 'Email', value: 'email' },
            { text: 'Vorname', value: 'firstname'},
            { text: 'Nachname', value: 'lastname'},
            { text: 'Ist Mitglied', value: 'isMember'},
            { text: 'Mitgliedschaft bestätigen'},
            { text: 'Mitgliedschaft ablehnen'},
        ]
    }),
    methods: {
        fetchData: function(){
            this.$axios.get('/users')
            .then(response => this.users = response.data)
            .catch(error => console.log(error))
        },
        confirm: function(accountId){
            this.$axios.post('/users/'+accountId+'/membership/confirm')
            .then(() => this.users = this.users.filter(user => user.id != accountId))
        },
        cancel: function(accountId){
            this.$axios.post('/users/'+accountId+'/membership/decline')
            .then(() => this.users = this.users.filter(user => user.id != accountId))
        }
    },
    computed: {
        newMembers: function(){
             return this.users.filter(user => user.isVerified && user.isConfirmedMember === null)
        }
    },
    created: function(){
        this.fetchData()
    }
}
</script>