<template>
    <v-footer height="auto" class="grey darken-3">
        <v-flex xs12>
            <v-layout ma-4 wrap align-center justify-center>
                <v-btn large outlined rounded class="cyan--text" :href="'mailto: ' + email">Kontaktieren Sie uns</v-btn>
            </v-layout>
            <v-layout align-center justify-space-between >
                <v-flex xs6 offset-sm2 offset-md3>
                    <v-list class="grey darken-3">
                        <v-list-item v-for="link in links.leftSide" :key="link.url">
                            <v-list-item-content>
                                <a @click="navigateTo(link.url)" class="cyan--text">{{ link.text }}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-flex>
                <v-flex xs4 sm4 md6>
                    <v-list class="grey darken-3">
                        <v-list-item v-for="link in links.rightSide" :key="link.url">
                            <v-list-item-content>
                                <a @click="navigateTo(link.url)" class="cyan--text">{{ link.text }}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-flex>
            </v-layout>
            <v-flex xs12 py-3 text-center white--text>
                &copy;2018 — Ferienpass Pfyn
                <p>Alle Rechte vorbehalten</p>
            </v-flex>
        </v-flex>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({
        email: "ferienpass@familienverein-pfyn.ch",
        links: {
            leftSide: [
                { text: "AGBs", url: "/rules" },
                { text: "Datenschutz", url: "/privacy" },
                { text: "Login", url: "/login" },
            ],
            rightSide: [
                { text: "Kurse", url: "/courses" },
                { text: "Team", url: "/team" },
                { text: "Spielregeln", url: "/rules" },
                { text: "Registrieren", url: "/register" },
            ]
        }
    }),
    methods: {
        navigateTo: function(url){
            this.$router.push(url);
        }
    }
}
</script>

