<template>
    <div>
        <v-navigation-drawer v-if="isSmallDevice" v-model="drawerExpanded" app>
            <v-layout align-center justify-center>
                <v-list two-line class="my-3">
                <v-list-item>
                    <img :src="logo.source" alt='LOGO' @click="$emit('navigateTo', '/')" :height="logo.height.other"/>
                </v-list-item>
            </v-list>
            </v-layout>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="link in pageLinks" :key="link.url">
                    <v-list-item-content @click="$emit('navigateTo', link.url)">
                        {{ link.text }}
                    </v-list-item-content>
                </v-list-item>   
            </v-list>
            <v-divider></v-divider>
            <v-list v-if="$store.getters.isLoggedIn">
                <v-list-item to="/my/courses" active-class>
                    <v-list-item-title>Meine Kurse</v-list-item-title>
                </v-list-item>
                <v-list-item to="/my/invoices" active-class>
                     <v-list-item-title>Rechnungen</v-list-item-title>
                </v-list-item>
                <v-list-item to="/my/account" active-class>
                    <v-list-item-title>Account</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$store.getters.isAdmin" to="/admin/overview" active-class>
                    <v-list-item-title>Admin</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$store.dispatch('logout')" active-class>
                     <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-list v-else>
                <v-list-item avatar>
                    <v-btn block outlined @click="$emit('navigateTo', '/login')" color="teal lighten-2">login
                        <v-icon right dark>exit_to_app</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        
        <v-app-bar app fixed color="white" :height="navbarHeight">
            <!-- smartphones/tablets -->
            <template v-if="isSmallDevice">
                <v-btn icon @click.stop="drawerExpanded = !drawerExpanded"><v-icon>mdi-menu</v-icon></v-btn>
                <v-container fill-height>
                    <v-layout align-center justify-center>
                        <v-flex v-if="isTablet" xs2>
                            <img :src="logo.source" @click="$emit('navigateTo', '/')" alt="LOGO" :height="logo.height.other">  
                        </v-flex>
                        <v-flex v-else xs3> <!-- or xs5 depends on smartphone screen -->
                            <img :src="logo.source" @click="$emit('navigateTo', '/')" alt="LOGO" :height="logo.height.other">   
                        </v-flex>
                    </v-layout>
                </v-container>
            </template>
            <!-- laptops/desktops -->
            <template v-else>
            <img class="clickable" :src="logo.source" @click="$emit('navigateTo', '/')" alt="LOGO" :height="logo.height.desktop">
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-xs-only">
                <template v-for="link in pageLinks">
                    <v-btn text large @click="$emit('navigateTo', link.url)"
                            v-bind:key="link.id">{{ link.text }}</v-btn>
                </template>
                <AccountDropdown />
            </v-toolbar-items>
            <v-btn v-if="!$store.getters.isLoggedIn" @click="$emit('navigateTo', '/login')" outlined color="teal white--text hidden-xs-only">Login</v-btn>
            </template>
        </v-app-bar>
    </div>
</template>

<script>
import AccountDropdown from '@/components/account/AccountDropdown.vue'

export default {
    name: 'NavbarGuest',
    components: {
        AccountDropdown
    },
    data: () => ({
        logo: {
            source:  require('@/assets/logos/FV_Logo_neu.jpg'),
            tile: true,
            height: {
                desktop: "75px",
                other: "70px"
            }
        },
        drawerExpanded: false,
        navbarHeight: "90px",
        pageLinks: [
            { id: 1, text: "Kurse", url: '/courses' },
            { id: 2, text: "Team", url: '/team'},
            { id: 3, text: "Spielregeln", url: '/rules' }]
    }),
    computed: {
        isSmallDevice: function () {
            var size = this.$vuetify.breakpoint.name;
            if(size == 'xs' || size == 'sm')
                return true;  
            else
                return false; 
        },
        isTablet: function(){
            return (this.$vuetify.breakpoint.name == 'sm');
        }
    }
}
</script>

<style>
.clickable {
    cursor: pointer;
  }
</style>
