<template>
    <v-container fill-height>
        <v-layout wrap align-center justify-center>
            <v-layout justify-center>
                <v-dialog v-model="cancelBookingVisible" persistent max-width="450">
                    <v-card>
                        <v-card-title class="headline teal--text">Wollen Sie diese Buchung wirklich stornieren?</v-card-title>
                        <v-card-text v-if="bookingToCancel != null">
                            Ihre Buchung für <i>{{bookingToCancel.title}}</i> wird unwiderruflich storniert und der Platz im Kurs wird für andere Kinder freigeben. Solange es noch Plätze hat, können Sie den Kurs jederzeit nochmals buchen.
                            <p><i>Kurse können nur bis Ende Phase 2 storniert werden.</i></p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey" text @click.native="resetCancelBooking()">Abbrechen</v-btn>
                            <v-btn color="red" text @click.native="cancelBooking()">Stornieren</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
            <v-flex xs12>
                <h4 class="display-1 teal--text">Meine Kurse:</h4>
            </v-flex>
            <v-flex xs12 md8 v-for="person in $store.state.persons" :key="person.id">
                <v-card>
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline teal--text">{{ person.firstname }}</h3>
                            <span class="caption grey--text text--darken-3">{{ person.age }}</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="$store.getters.getBookingsByPersonId(person.id).length === 0">
                            Keine Kurse gebucht
                        </div>
                        <template v-for="booking in $store.getters.getBookingsByPersonId(person.id)">
                            <v-layout wrap justify-space-arounded mt-3 :key="booking.personId">
                                <v-flex xs12 sm8>
                                    <v-flex xs12>
                                        <big><b v-bind:class="{'error--text': !$store.getters.getCourseById(booking).confirmed && $store.getters.getCourseById(booking).confirmed !== null}">{{ $store.getters.getCourseById(booking).title }} {{$store.getters.getCourseById(booking).confirmed === null || $store.getters.getCourseById(booking).confirmed === true ? '' : '- Abgesagt'}}</b></big>
                                    </v-flex>
                                    <v-flex xs9>
                                        <span class="grey--text text--darken-2">
                                            <i>
                                                {{ $store.getters.getDisplayDate($store.getters.getCourseById(booking).start, $store.getters.getCourseById(booking).end) }}, {{ $store.getters.getCourseById(booking).location }}
                                                <p>Start: {{ $store.getters.getCourseById(booking).startsAt }} | Ende: {{ $store.getters.getCourseById(booking).endsAt }}</p>
                                            </i>
                                            Preis: {{ ($store.state.user.isConfirmedMember) ? $store.getters.getCourseById(booking).costMember : $store.getters.getCourseById(booking).costNonMember }} Fr.
                                            <p v-if="$store.getters.getCourseById(booking).special"><b>Besonderes:</b> {{ $store.getters.getCourseById(booking).special }}</p>
                                            <p v-if="$store.getters.getCourseById(booking).venue"><b>Treffpunkt:</b> {{ $store.getters.getCourseById(booking).venue }}</p>
                                        </span>
                                    </v-flex> 
                                    <v-flex v-if="$store.getters.getCourseById(booking).helpers && needsSupport.includes(booking)" xs12 class="teal--text body-2 mb-3">
                                        Du wirst gerne als Helfer gebraucht.
                                    </v-flex>   
                                </v-flex>  
                                <v-flex xs9 sm4 mt-2>
                                    <v-btn v-if="$store.getters.isBeforePhase3" text color="red" @click="openCancelBooking(person.id, booking)">
                                        stornieren
                                    </v-btn>
                                </v-flex>                 
                            </v-layout>
                            <v-divider :key="booking.personId"></v-divider>
                        </template>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'MyCourses',
    data: () => ({
        cancelBookingVisible: false,
        bookingToCancel: null,
        needsSupport: []
    }),
    methods: {
        resetCancelBooking: function(){
            this.cancelBookingVisible = false
            this.bookingToCancel = null
        },
        openCancelBooking: function(personId, courseId){
            this.bookingToCancel = {}
            this.bookingToCancel.personId = personId
            this.bookingToCancel.courseId = courseId
            this.bookingToCancel.title = this.$store.getters.getCourseById(courseId).title
            this.cancelBookingVisible = true
        },
        cancelBooking: function(){
            this.cancelBookingVisible = false
            let personId = this.bookingToCancel.personId
            let courseId = this.bookingToCancel.courseId
            this.$axios.post('/course/'+courseId+'/cancel', {
                personId: personId
            })
            .then(response => {
                this.$store.commit('removeBooking', {
                    personId: personId,
                    courseId: courseId
                })
            })
            .catch(error => {
                console.log(error.response.data)
            })
            .finally(() => {
                this.resetCancelBooking();
            })
        },
    },
    created: function(){
        this.$axios.get('/helpers')
        .then(response => this.needsSupport = response.data)
        .catch(error => console.log(error))
    }
}
</script>

<style>

</style>