<template>
    <v-layout wrap v-if="coursesToReview">
        <v-flex xs12 mt-3 mb-2>
            <h5 class="headline teal--text">Zu wenig Teilnehmer:</h5>
        </v-flex>
        <v-flex xs12>
            <template v-if="loadingData">
                <v-progress-linear color="teal" :indeterminate="true"></v-progress-linear>
            </template>
            <v-data-table v-else :headers="headers" :items="coursesToReview" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>Min: {{ item.minSeats }}</td>
                        <td>{{ item.participants }}</td>
                        <td>
                            <v-btn text icon color="teal" @click="showParticipants(item.id)">
                                <v-icon>format_list_bulleted</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn text icon color="teal" @click="openConfirm(item)">
                                <v-icon>check_circle</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn text icon color="error" @click="openCancel(item)">
                                <v-icon>cancel</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <!-- cancel dialog -->
        <v-dialog v-model="cancelCourseVisible" v-if="courseToModify" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline error--text teal--text font-weight-regular justify-space-between">
                    <span>Möchten Sie den Kurs wirklich absagen?</span>
                </v-card-title>
                <v-card-text class="subheading">
                    Möchten Sie den Kurs <i>{{ courseToModify.title }}</i> wirklich absagen?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetModifyCourse()">
                        Abbrechen
                    </v-btn>
                    <v-btn color="error" text="text" @click="cancel(courseToModify.id)">
                        Absagen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- confirm dialog -->
        <v-dialog v-model="confirmCourseVisible" v-if="courseToModify" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline teal--text teal--text font-weight-regular justify-space-between">
                    <span>Möchten Sie den Kurs trotzdem durchführen?</span>
                </v-card-title>
                <v-card-text class="subheading">
                    Möchten Sie den Kurs <i>{{ courseToModify.title }}</i> trotz zu wenig Teilnehmern durchführen?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetModifyCourse()">
                        Abbrechen
                    </v-btn>
                    <v-btn color="teal" text="text" @click="confirm(courseToModify.id)">
                        Durchführen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- list of participants -->
        <v-dialog v-model="participantsDialog" scrollable persistent :overlay="false" max-width="500px" transition="dialog-transition">
             <v-card>
                <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                    <span>Teilnehmerliste</span>
                </v-card-title>
                <v-card-text class="subheading">
                    <v-progress-linear v-if="!participants" color="teal" :indeterminate="true"></v-progress-linear>
                    <v-list v-else ref="participantList">
                        <v-subheader>
                            {{ $store.getters.getCourseById(participants[0].CourseBooking.courseId).title }} - {{ formatCourseDateTime($store.getters.getCourseById(participants[0].CourseBooking.courseId)) }}
                        </v-subheader>
                        <template v-for="(participant, index) in participants">
                            <v-list-item :key="index" avatar>
                                <v-list-item-content>
                                    {{ participant.firstname }} {{ participant.lastname }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="index"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="grey" text="text" @click="resetParticipants()">
                        Schliessen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
export default {
    name: 'NotEnoughParticipants',
    data: () => ({
        coursesToReview: null,
        loadingData: true,
        courseToModify: null,
        cancelCourseVisible: false,
        confirmCourseVisible: false,
        participantsDialog: false,
        participants: null,
        headers: [
            { text: 'Titel', value: 'title' },
            { text: '#Min. Buchungen', value: 'minSeats'},
            { text: '#Teilnehmer', value: 'participants'},
            { text: 'Teilnehmerliste', value: 'participantsList'},
            { text: 'Trotzdem bestätigen', value: 'confirm'},
            { text: 'Ablehnen', value: 'reject'},
        ]
    }),
    methods: {
        fetchData: function(){
            this.loadingData = true
            this.$axios.get('/courses/notEnoughParticipants')
            .then(result => this.coursesToReview = result.data)
            .catch(error => console.log(error))
            .finally(() => this.loadingData = false)
        },
        openConfirm: function(course){
            this.courseToModify = course
            this.confirmCourseVisible = true
        },
        confirm: function(courseId){
            this.modifyCourse(courseId, 'confirm')
        },
        openCancel: function(course){
            this.courseToModify = course
            this.cancelCourseVisible = true
        },
        cancel: function(courseId){
            this.modifyCourse(courseId, 'cancel')
        },
        resetModifyCourse: function(){
            this.cancelCourseVisible = false
            this.confirmCourseVisible = false
            this.courseToModify = null
        },
        modifyCourse: function(courseId, action){
            this.$axios.post('/courses/'+courseId+'/'+action)
            .then(() => {
                this.coursesToReview = this.coursesToReview.filter(course => course.id != courseId)
                if(action === 'confirm'){
                    this.$store.commit('confirmCourse', courseId)
                } else if(action === 'cancel'){
                    this.$store.commit('cancelCourse', courseId)
                }
            })
            .catch(error => console.log(error))
            .finally(this.resetModifyCourse())
        },
        resetParticipants: function(){
            this.participantsDialog = false
            this.participants = null
        },
        showParticipants: function(courseId){
            this.getParticipants(courseId)
            this.participantsDialog = true
        },
        getParticipants: function(courseId){
            this.$axios.get('/courses/'+courseId+'/participants')
            .then(response => {
                this.participants = response.data
            })
            .catch(error => console.log(error))
        },
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD. MMMM')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD. MMMM YYYY')
            } else {
                dateString += ' ' + this.$moment(course.start).format('YYYY')
            }
            dateString += ', ' + course.startsAt + ' - ' + course.endsAt + ' Uhr'
            return dateString
        }
    },
    created: function(){
        this.fetchData()
    },
    watch: {
        '$route': 'fetchData' 
    }
}
</script>

<style>

</style>