<template>
    <v-container grid-list-xs fill-height mt-3>
        <v-layout wrap>
            <v-flex xs12 sm6 offset-sm3>
                <v-card v-if='submitted' align-center justify-center>
                    <v-container fluid>
                        <v-card-title primary-title>
                            <h3 class="display-2 teal--text lighten-4 font-weight-light">Erfolgreich</h3>
                        </v-card-title>
                        <v-card-text>
                            {{ emailSentText }}
                        </v-card-text>
                        <v-layout wrap align-center justify-center>
                            <v-btn block large class="teal white--text" @click="$emit('navigateTo', '/Login')">
                                zum Login
                            </v-btn>
                        </v-layout>
                    </v-container>
                </v-card>
                <v-card v-else>
                    <v-container fluid>
                        <v-card-title primary-title>
                            <h3 class="display-2 teal--text lighten-4 font-weight-light">{{ text.title }}</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field :label="labels.email" v-model="email" color="teal" type="email" required></v-text-field>
                                <v-text-field :label="labels.password" v-model="password" color="teal" type="password"
                                    required></v-text-field>
                                <v-text-field :label="labels.repeatPassword" v-model="passwordRepeated" color="teal"
                                    type="password" required></v-text-field>
                                <v-btn  block large class="teal white--text" @click="submit" :disabled="!valid">
                                    Registrieren
                                </v-btn>
                            </v-form>
                        </v-card-text>
                        <v-alert color="error" icon="true" dismissible v-model="showAlert" transition="slide-y-reverse-transition">
                            {{ alertText }}
                        </v-alert>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import EmailValidator from 'email-validator';

export default {
    name:  'Register',
    data: () => ({
        submitted: false,
        showAlert: false,
        alertText: '',
        text: {
            next: 'Weiter',
            title: "Neues Konto erstellen"
        },
        labels: {
             email: 'E-mail',
             password: 'Passwort',
             repeatPassword: 'Passwort wiederholen' ,
        },
        email: '',
        password: '',
        passwordRepeated: '',
        emailSentText: 'Bitte überprüfen Sie Ihren Email-Posteingang für weitere Instruktionen. Vielen Dank! \nPS: Unter Umständen finden Sie den Bestätigungs-Link im Spam-Ordner'
    }),
    methods: {
        submit() {
            var self = this
            if (this.$refs.form.validate()) {
                this.$axios.post('/register', {
                    email: this.email,
                    password: this.password,
                    pwRepeated: this.passwordRepeated
                })
                .then(() => {
                    self.submitted = true;
                })
                .catch(error => {
                    if(error.response.status === 409) {
                        self.alertText = 'Benutzer mit Email '+self.email+' existiert bereits'
                        self.email = ''
                    } else if(error.response.status === 500) {
                        self.alertText = 'Etwas ist schiefgelaufen. Bitte kontaktieren Sie den Systemadministrator.'
                    } else {
                        self.alertText = error.response.data
                    }
                    self.password = ''
                    self.passwordRepeated = ''
                    self.showAlert = true
                })
            }   
        }
    },
    computed: {
        valid: function () {
            if (EmailValidator.validate(this.email)) {
                if (this.password === "")
                    return false;
                if (this.password === this.passwordRepeated)
                    return true;
                else
                    return false;
            }
            else
                return false;
        }
    }
}
</script>

