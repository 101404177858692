<template>
    <v-layout wrap>
        <v-flex xs12 my-2>
            <h5 class="headline teal--text">Abgesagte Kurse:</h5>
        </v-flex>
        <v-flex xs12>
            <v-data-table :headers="headers" :items="$store.getters.canceledCourses" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>{{ formatCourseDateTime(item) }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'CanceledCourses',
    data: () => ({
        headers: [
            { text: 'Titel', value: 'title' },
            { text: 'Wann', value: 'start'}
        ]
    }),
    methods: {
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD. MMMM')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD. MMMM YYYY')
            } else {
                dateString += ' ' + this.$moment(course.start).format('YYYY')
            }
            dateString += ', ' + course.startsAt + ' - ' + course.endsAt + ' Uhr'
            return dateString
        }
    }
}
</script>