<template>
  <div>
    <v-parallax :height="parallaxHeight" :src="parallaxSource" :lazy-src="parallaxSource">
      <v-layout align-center column justify-center>
        <v-container fill-height>
          <v-layout wrap align-center>
            <v-flex class="text-center">
              <h1 :class="{'text-center white--text': true,
                          'display-4': $vuetify.breakpoint.smAndUp,
                          'display-3': $vuetify.breakpoint.xs}">{{ $store.state.ferienpass.title || 'Datum demnächst bekannt' }}</h1>
              <br class="spaceBetween">
              <h1 class="display-3 font-weight-light white--text">{{ ferienpassTimespan }}</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-layout>
    </v-parallax>
    <div class="parallaxSpace"></div>
    <v-container grid-list-xs>
      <v-layout justify-space-around items-center wrap>
        <v-flex xs12 sm8 md5 mt-5>
          <v-card text hover to="/courses" >
            <v-responsive>
              <v-img :src="require('@/assets/kursfinger.jpg')" :lazy-src="require('@/assets/kursfinger.jpg')" max-height="320px"></v-img>
            </v-responsive>
            <v-card-title primary-title>
              <v-flex xs>
                <h4 class="display-1">Kurse</h4>
              </v-flex>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 md5 mt-5>
          <v-card text hover to="/rules">
            <v-responsive>
              <v-img :src="require('@/assets/spielregeln.jpg')" :lazy-src="require('@/assets/spielregeln.jpg')" max-height="320px"></v-img>
            </v-responsive>
            <v-card-title primary-title>
              <h4 class="display-1">Spielregeln</h4>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 md5 mt-5>
          <v-card text hover to="/team">
            <v-responsive>
              <v-img :src="require('@/assets/team_wiese.jpg')" :lazy-src="require('@/assets/team_wiese.jpg')" max-height="320px"></v-img>
            </v-responsive>
            <v-card-title primary-title>
              <h4 class="display-1">Team</h4>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer class="spaceFooter" />
  </div>
</template>

<script>
import Footer from '../Footer.vue'

export default {
  name: 'LandingPage',
  components: {
    Footer
  },
  data() {
    return {
      counterTitle: 'Startet in:',
      parallaxHeight: 900,
      sources: [
        require('@/assets/landing-kids-campfire.jpg'),
        require('@/assets/landing-kids-pulling.jpg')
      ]
    }
  },
  computed: {
    ferienpassTimespan: function () {
      if(this.$store.state.ferienpass.id){
        let startMonth = this.$moment(this.$store.state.ferienpass.start).format('MMMM')
        let endMonth = this.$moment(this.$store.state.ferienpass.end).format('MMMM')
        if(startMonth === endMonth){
          return this.$moment(this.$store.state.ferienpass.start).format('DD.') + ' - ' + this.$moment(this.$store.state.ferienpass.end).format('DD. MMMM YYYY')
        } else {
          return this.$moment(this.$store.state.ferienpass.start).format('DD. MMMM') + ' - ' + this.$moment(this.$store.state.ferienpass.end).format('DD. MMMM YYYY')
        }
      } else {
        return 'Wir freuen uns aufs nächste Mal'
      }
    },
    isTablet: function(){
        return (this.$vuetify.breakpoint.name == 'sm');
    },
    parallaxSource: function(){
      let randomSource = this.sources[Math.floor(Math.random() * this.sources.length)];
      return randomSource
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .spaceBetween {
    margin-top: 40px
  }

  .parallaxSpace {
    margin-top: 40px
  }

  .spaceFooter {
    margin-top: 150px
  }

  .clickable {
    cursor: pointer;
  }
</style>
