<template>
    <v-card>
        <v-card-title primary-title>
            <div>
                <h3 class="headline teal--text">Meine persönlichen Daten</h3>
            </div>
        </v-card-title>
        <v-card-text>
            <v-layout wrap align-center>
                <v-flex xs8>
                    <v-layout wrap>
                        <v-flex xs12 class="teal--text subheading">
                            Rechnungsadresse:
                        </v-flex>
                        <v-flex xs12>
                            {{ $store.state.user.firstname }} {{ $store.state.user.lastname }}
                        </v-flex>
                        <v-flex xs12>
                            {{ $store.state.user.address }}
                        </v-flex>
                        <v-flex xs2>
                            {{ $store.state.user.ZIP}}
                        </v-flex>
                        <v-flex xs2>
                            {{ $store.state.user.place}}
                        </v-flex>
                    </v-layout>
                    <v-layout mt-3 wrap align-center>
                        <v-flex xs7 sm5 class="teal--text body-1">
                            Notfallnummer:
                        </v-flex>
                        <v-flex xs5 class="caption">
                            {{ $store.state.user.emergencyNumber}}
                        </v-flex>
                    </v-layout>
                    <v-layout mt-3 wrap align-center>
                        <v-flex xs7 sm5 class="teal--text body-1">
                            Mitgliederstatus:
                        </v-flex>
                        <v-flex xs5 class="caption">
                            {{ memberStatus }}
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs1>
                    <v-btn text icon color="teal" @click="edit()">
                        <v-icon>edit</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-bottom-sheet v-model="showEdit" v-if="userData">
            <v-card>
                <v-card-title primary-title>
                    <div>
                        <h3 class="headline mb-0 teal--text">Neue Rechnungsadresse</h3>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-layout wrap align-center justify-center>
                        <v-flex xs12 md5>
                            <v-text-field color="teal" v-model="userData.firstname" type="text" label="Vorname" required
                                :rules="[v => !!v || 'Bitte Vorname eingeben']"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md5 offset-md2>
                            <v-text-field color="teal" v-model="userData.lastname" type="text" label="Nachname" required
                                :rules="[v => !!v || 'Bitte Nachname eingeben']"></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-flex xs12>
                        <v-text-field color="teal" v-model="userData.address" type="text" label="Adresse"
                            append-icon="place" required :rules="[v => !!v || 'Bitte Adresse eingeben']"></v-text-field>
                    </v-flex>
                    <v-layout wrap align-center justify-center>
                        <v-flex xs12 md5>
                            <v-text-field color="teal" v-model="userData.ZIP" type="text" label="PLZ" required
                                :rules="[v => !!v || 'Bitte PLZ eingeben']"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md5 offset-md2>
                            <v-text-field color="teal" v-model="userData.place" type="text" label="Ort" required
                                :rules="[v => !!v || 'Bitte Ort eingeben']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field color="teal" v-model="userData.emergencyNumber" type="text"
                                label="Notfallnummer" required
                                :rules="[v => !!v || 'Bitte Notfallnummer eingeben. Wir werden Sie nur in dringenden Notfällen kontaktieren']">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs 12>
                            <v-btn block color="teal" dark @click="sendChanges">Speichern</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </v-card>
</template>

<script>
export default {
    name: 'MyData',
    data: () => ({
        userData: {},
        showEdit: false,
    }),
    computed: {
        memberStatus: function(){
            if(this.$store.state.user.isMember){
                if(this.$store.state.user.isConfirmedMember){
                    return 'Bestätigtes Mitglied'
                } else {
                    return 'Nichtbestätigtes Mitglied'
                }
            }
            return 'Nichtmitglied'
        }
    },
    methods: {
        resetEdit: function () {
            this.showEdit = false
            this.userData.firstname = this.$store.state.user.firstname
            this.userData.lastname = this.$store.state.user.lastname
            this.userData.address = this.$store.state.user.address
            this.userData.ZIP = this.$store.state.user.ZIP
            this.userData.place = this.$store.state.user.place
            this.userData.emergencyNumber = this.$store.state.user.emergencyNumber
        },
        edit: function(){
            this.resetEdit()
            this.showEdit = true
        },
        sendChanges: function(){
            this.$axios.post('/my/account/changeData', {
                firstname: this.userData.firstname,
                lastname: this.userData.lastname,
                address: this.userData.address,
                ZIP: this.userData.ZIP,
                place: this.userData.place,
                emergencyNumber: this.userData.emergencyNumber
            })
            .then(response => {
                console.log(response.data)
                this.$store.commit('setUser', response.data)
            })
            .catch(error => console.log(error))
            .finally(() => this.resetEdit()) 
        }
    }
}
</script>

<style>

</style>