<template>
    <v-dialog v-model="newCourseDialogVisible" scrollable persistent max-width="700px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn block text color="teal" v-bind="attrs" v-on="on">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline teal--text">Neuer Kurs</v-card-title>
            <!-- new course form -->
            <v-card-text>
                <v-form ref="newCourseForm">
                    <v-text-field color="teal" v-model="newCourse.title" type="text" label="Titel" required
                        :rules="[v => !!v || 'Bitte Titel eingeben']"></v-text-field>
                    <v-textarea v-model="newCourse.description" color="teal" auto-grow
                        :rules="[v => !!v || 'Bitte Beschreibung eingeben']">
                        <template slot="label">Beschreibung <v-icon small>description</v-icon></template>
                    </v-textarea>
                    <v-layout wrap>
                        <!-- age -->
                        <v-flex xs12 sm5>
                            <v-select :items="$store.state.ageGroups" placeholder="Minimum Klasse" label="Minimum Klasse"
                                v-model="newCourse.minAge" color="teal" append-icon="accessibility_new"></v-select>
                        </v-flex>
                        <v-flex xs12 sm5 offset-sm2>
                            <v-select :items="$store.state.ageGroups" placeholder="Maximum Klasse" label="Maximum Klasse"
                                v-model="newCourse.maxAge" color="teal" append-icon="accessibility_new"></v-select>
                        </v-flex>
                        <!-- cost -->
                        <v-flex xs12 sm5>
                            <v-text-field v-model.number="newCourse.costMember" type="number" label="Kosten Mitglieder"
                                placeholder="z.B: 22.5" color="teal" min="0" append-icon="money"
                                :rules="[v => !!v || 'Bitte Kosten eingeben']"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm5 offset-sm2>
                            <v-text-field v-model.number="newCourse.costNonMember" type="number"
                                label="Kosten Nicht-Mitglieder" placeholder="z.B: 22.5" color="teal" min="0"
                                append-icon="money" :rules="[v => !!v || 'Bitte Kosten eingeben']"></v-text-field>
                        </v-flex>
                        <!-- location -->
                        <v-flex xs12>
                            <v-text-field v-model="newCourse.location" label="Adresse/Ort"
                                placeholder="z.B: Hauptstrasse 2, 8505 Pfyn" color="teal" append-icon="location_on"
                                :rules="[v => !!v || 'Bitte Adresse/Ort eingeben', v => v.length <= 250 || 'Nicht mehr als 250 Zeichen']"></v-text-field>
                        </v-flex>
                        <!-- venue -->
                        <v-flex xs12>
                            <v-text-field v-model="newCourse.venue" label="Treffpunkt" placeholder="z.B: vor dem Haus"
                                color="teal" append-icon="my_location" :rules="[v => v.length <= 250 || 'Nicht mehr als 250 Zeichen']"></v-text-field>
                        </v-flex>
                        <!-- min seats -->
                        <v-flex xs12 sm5>
                            <v-text-field v-model.number="newCourse.minSeats" type="number"
                                label="Minimum Teilnehmer" placeholder="z.B: 3" color="teal" min="0"></v-text-field>
                        </v-flex>
                        <!-- max seats -->
                        <v-flex xs12 sm5 offset-sm2>
                            <v-text-field v-model.number="newCourse.maxSeats" type="number"
                                label="Maximale Teilnehmer" placeholder="z.B: 15" color="teal" min="0"></v-text-field>
                        </v-flex>
                        <!-- leadership -->
                        <v-flex xs12 sm8>
                            <v-text-field v-model="newCourse.leadership" label="Kurs-Leitung" color="teal"
                                :rules="[v => !!v || 'Bitte Leitung eingeben']" prepend-icon="people"></v-text-field>
                        </v-flex>
                        <!-- helpers -->
                        <v-flex xs12 sm3 offset-sm1>
                            <v-checkbox label="benötigt Helfer" v-model="newCourse.helpers" :value="false" color="teal"></v-checkbox>
                        </v-flex>
                        <!-- special -->
                        <v-flex xs12>
                            <v-text-field v-model="newCourse.special" label="Spezielles" color="teal" append-icon="info"
                                placeholder="z.B: wetterfeste Schuhe mitnehmen"></v-text-field>
                        </v-flex>
                    </v-layout>
                    <!-- image ulr -->
                    <v-flex xs12>
                        <v-text-field v-model="newCourse.imgSrc" label="Bild URL" color="teal" append-icon="add_photo_alternate"
                                placeholder="https://unsplash.com/photos/tvc5imO5pXk"
                                :rules="[v => !!v || 'Bitte Bild URL einfügen']"></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 mt-3>
                        <span class="subheading grey--text">Datum und Uhrzeit:</span>
                    </v-flex>
                    <!-- course date & time -->
                    <v-layout wrap align-center justify-center>
                        <!-- start date -->
                        <v-flex xs12 sm6>
                            <v-dialog ref="courseDayDialog" v-model="dateDialogVisible"
                                :return-value.sync="newCourse.startDate" persistent lazy full-width width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field slot="activator" v-model="newCourse.startDate"
                                        :rules="[v => !!v || 'Bitte Start Datum eintragen']" label="Start Datum"
                                        prepend-icon="event" readonly color="teal" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="newCourse.startDate" scrollable color="teal lighten-2"
                                    :min="$moment($store.state.ferienpass.start).subtract(1, 'days').format()"
                                    :max="$moment($store.state.ferienpass.end).format()"
                                    @input="newCourse.endDate = newCourse.startDate">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="dateDialogVisible = false">
                                        Abbrechen</v-btn>
                                    <v-btn text color="teal" @click="$refs.courseDayDialog.save(newCourse.startDate)">
                                        <!-- @click function -> read:  https://forum.vuejs.org/t/refs-created-dynamically-seem-to-need-to-be-accessed-with-array-subscript-0/17725 -->
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <!-- end date -->
                        <v-flex xs12 sm6>
                            <v-dialog ref="courseEndDayDialog" v-model="endDateDialogVisible"
                                :return-value.sync="newCourse.endDate" persistent lazy full-width width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field slot="activator" v-model="newCourse.endDate"
                                        :rules="[v => !!v || 'Bitte End Datum eintragen']" label="End Datum"
                                        prepend-icon="event" readonly color="teal" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="newCourse.endDate" scrollable color="teal lighten-2"
                                    :min="$moment(newCourse.startDate).subtract(1, 'days').format()"
                                    :max="$moment($store.state.ferienpass.end).format()">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="endDateDialogVisible = false">
                                        Abbrechen</v-btn>
                                    <v-btn text color="teal" @click="$refs.courseEndDayDialog.save(newCourse.endDate)">
                                        <!-- @click function -> read:  https://forum.vuejs.org/t/refs-created-dynamically-seem-to-need-to-be-accessed-with-array-subscript-0/17725 -->
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                        <!-- start time -->
                        <v-flex xs12 sm6>
                            <v-dialog ref="courseDayStartTimeDialog" v-model="startTimeDialogVisible" lazy
                                persistent full-width width="290px" :return-value.sync="newCourse.startsAt">
                                <template v-slot:activator="{ on }">
                                    <v-text-field slot="activator" label="Startzeit" v-model="newCourse.startsAt"
                                        prepend-icon="access_time" readonly
                                        :rules="[v => !!v || 'Bitte Startzeit eintragen']" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-model="newCourse.startsAt" actions format="24hr" color="teal">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="startTimeDialogVisible = false">
                                        Abbrechen</v-btn>
                                    <v-btn text color="teal"
                                        @click="$refs.courseDayStartTimeDialog.save(newCourse.startsAt)">
                                        OK</v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-flex>
                        <!-- end time -->
                        <v-flex xs12 sm6>
                            <v-dialog ref="courseDayEndTimeDialog" v-model="endTimeDialogVisible" lazy
                                persistent full-width width="290px" :return-value.sync="newCourse.endsAt">
                                <template v-slot:activator="{ on }">
                                    <v-text-field slot="activator" label="Endzeit" v-model="newCourse.endsAt"
                                        prepend-icon="access_time" readonly
                                        :rules="[v => !!v || 'Bitte Endzeit eintragen']" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-model="newCourse.endsAt" actions format="24hr" color="teal">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="grey darken-1" @click="endTimeDialogVisible = false">
                                        Abbrechen</v-btn>
                                    <v-btn text color="teal"
                                        @click="$refs.courseDayEndTimeDialog.save(newCourse.endsAt)">
                                        OK</v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>                    
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click.native="newCourseDialogVisible = false">Abbrechen</v-btn>
                <v-btn color="teal" text @click.native="createNewCourse">Erstellen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'NewCourseDialog',
    data() {
        return {
            newCourseDialogVisible: false,
            dateDialogVisible: false,
            endDateDialogVisible: false,
            startTimeDialogVisible: false,
            endTimeDialogVisible: false,
            newCourse: {
                title: '',
                description: '',
                minAge: '',
                maxAge: '',
                costMember: '',
                costNonMember: '',
                startDate: '',
                endDate: '',
                startsAt: '',
                endsAt: '',
                location: '',
                minSeats: '',
                maxSeats: '',
                imgSrc: '',
                leadership: '',
                special: '',
                venue: '',
                helpers: false
            },
        }
    },
    methods: {
        createNewCourse: function(){
            if(this.newCourse.imgSrc.includes('data:image/')){
                // probably they messed up the URL of the image, puting the URL of the localy chached image
                this.newCourse.imgSrc = null
                alert('URL darf nicht mit data:image/ beginnen.') 
            } 
            if(this.$refs.newCourseForm.validate()){
                this.$emit('newCourse', this.newCourse)
                this.newCourseDialogVisible = false
                this.dateDialogVisible = false
                this.endDateDialogVisible = false
                this.startTimeDialogVisible = false
                this.endTimeDialogVisible = false
            }
        },
        resetNewCourse: function(){
            this.newCourse = {
                title: '',
                description: '',
                minAge: '',
                maxAge: '',
                costMember: '',
                costNonMember: '',
                startDate: '',
                endDate: '',
                startsAt: '',
                endsAt: '',
                location: '',
                minSeats: '',
                maxSeats: '',
                imgSrc: '',
                leadership: '',
                special: '',
                venue: '',
                helpers: false
            }
        }
    }
}
</script>