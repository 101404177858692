<template>
    <v-container fill-height>
        <v-layout wrap align-center>
            <!-- new -->
            <v-flex xs12 v-if="!$store.state.ferienpass.id">
                <v-layout wrap justify-center>
                    <v-flex xs12 sm10 lg8>
                        <v-btn block large to="/admin/new/event" color="teal white--text">Neu erstellen</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <!-- current -->
            <v-flex xs12 v-else>
                <CurrentEvent />
            </v-flex>
            <v-flex xs12>
                <UserOverview :users="users" />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import CurrentEvent from '@/components/admin/CurrentEvent.vue'
import UserOverview from '@/components/admin/users/UserOverview.vue'

export default {
    name: 'AdminOverview',
    components: {
        CurrentEvent,
        UserOverview
    },
    data: () => ({
        users: null
    }),
    mounted(){
        this.fetchData()
    },
    watch: {
        '$route'(val){
            if(val.fullPath === "/admin/overview"){
                this.fetchData()
            }
        }
    },
    methods: {
        fetchData: function(){
            this.$axios.get('/users')
            .then(response => {
                this.users = response.data
                this.loading = false
            })
            .catch(error => console.log(error))
        }
    },
}
</script>

