<template>
    <v-layout wrap justify-center>
        <v-flex xs12 sm10 lg8>
            <v-progress-linear v-if="!users" color="teal" :indeterminate="true"></v-progress-linear>
            <v-card v-else>
                <v-card-title primary-title>
                    <div>
                        <h3 class="headline mb-0 teal--text">Benutzerverwaltung</h3>
                        <div></div>
                    </div>
                </v-card-title>
                <v-card-text>
                </v-card-text>
                <v-card-actions>
                    <v-badge right color="red" v-if="newMembers.length > 0">
                        <span slot="badge"><small>{{ newMembers.length }}</small></span> <!--slot can be any component-->
                        <v-btn text small color="teal" to="/admin/users/unconfirmed">Neumitglieder</v-btn>
                    </v-badge>
                    <v-btn text small color="teal" to="/admin/users/detailed">Übersicht</v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'UserOverview',
    props: ['users'],
    computed: {
        newMembers: function(){
            return this.users.filter(user => user.isVerified && user.isConfirmedMember === null)
        }
    }
}
</script>

<style>

</style>