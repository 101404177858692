<template>
    <v-layout wrap align-center  justify-center>
            <v-flex xs12 my-2>
                <h5 class="headline teal--text">Benutzer:</h5>
            </v-flex>
            <v-flex xs12 sm10 lg8 mb-4>
                <v-progress-linear v-if="!(users && invoices) || loading" color="teal" :indeterminate="true"></v-progress-linear>
                <v-data-table v-else :headers="headers" :items="users" hide-default-footer disable-pagination class="elevation-1">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.email }}</td>
                            <td>{{ item.firstname }}</td>
                            <td>{{ item.lastname }}</td>
                            <td>{{ item.emergencyNumber }}</td>
                            <td><v-icon @click="showKids(item.id)" color="teal">format_list_bulleted</v-icon></td>
                            <td><v-icon @click="modifyMemberState(item.id, item.isConfirmedMember)" :class="{ 'green--text': item.isConfirmedMember, 'red--text': !item.isConfirmedMember }">{{ item.isConfirmedMember ? 'check' : 'close' }}</v-icon></td>
                            <td v-if="invoiceAmount(item.id)">{{ invoiceAmount(item.id) }}</td>
                            <td v-else-if="$store.state.ferienpass.locked"><v-btn text color="orange" @click="sendInvoice(item)">Rechnung versenden</v-btn></td>
                            <td v-else>Kurse unbestätigt</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
            <!-- list of kids dialog -->
            <v-dialog v-model="personsDialog" scrollable persistent :overlay="false" max-width="500px"
                transition="dialog-transition">
                <v-card>
                    <v-card-title class="headline teal--text font-weight-regular justify-space-between">
                        <span>Kinder</span>
                    </v-card-title>
                    <v-card-text class="subheading">
                        <v-progress-linear v-if="!persons" color="teal" :indeterminate="true"></v-progress-linear>
                        <v-list v-else ref="kidsList">
                            <template v-for="(person, index) in persons">
                                <v-list-item :key="index">
                                    <v-list-item-content>
                                        {{ person.firstname }} {{ person.lastname }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider :key="`${index}-divider`"></v-divider>
                            </template>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="grey" text="text" @click="resetPersonsDialog()">
                            Schliessen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
</template>

<script>
export default {
    name: 'DetailedOverview',
    data: () => ({
        loading: false,
        users: null,
        invoices: null,
        personsDialog: false,
        persons: null,
        headers: [
            { text: 'Email', value: 'email' },
            { text: 'Vorname', value: 'firstname'},
            { text: 'Nachname', value: 'lastname'},
            { text: 'Notfallnummer', value: 'emergencyNumber' },
            { text: 'Kinder', value: 'kids'},
            { text: 'Ist Mitglied', value: 'isConfirmedMember'},
            { text: 'Rechnungsbetrag', value: ''}
        ]
    }),
    methods: {
        fetchData: function(){
            this.$axios.get('/users')
            .then(response => {
                this.users = response.data
                let invoicesToGet = []
                this.users.forEach(user => {
                    invoicesToGet.push(this.$axios.get('/users/' + user.id + '/invoice'))
                });
                Promise.all(invoicesToGet)
                .then(responses => {
                    let invoices = []
                    responses.forEach(response => {
                        const isEmpty = Object.keys(response.data).length === 0
                        if(!isEmpty){
                            invoices.push(response.data)
                        }
                    })
                    this.invoices = invoices
                })
                .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
        },
        invoiceAmount: function(accountId){
            let invoice = this.invoices.find(invoice => invoice.fk_accountId == accountId)
            return invoice ? invoice.amount + ' CHF' : null
        },
        showKids: function(userId) {
            this.$axios.get('/users/'+userId+'/persons')
            .then(response => {
                this.persons = response.data
                this.personsDialog = true
            })
            .catch(error => console.log(error))
        },
        resetPersonsDialog: function(){
            this.personsDialog = false
            this.persons = null
        },
        modifyMemberState: function(userId, currentState){
            this.loading = true
            let action = currentState ? 'decline' : 'confirm'
            this.$store.commit('closeSnackbar')
            this.$axios.post('/users/'+userId+'/membership/'+action)
            .then(() => {
                this.users.find(user => user.id === userId).isConfirmedMember = !currentState
                this.$store.commit('setSnackbarColor', 'success')
                this.$store.commit('setSnackbarMultiLine', true)
                this.$store.commit('setSnackbarText', 'Mitglieder-Status von '+this.users.find(user => user.id === userId).email+' erfolgreich geändert')
                this.$store.commit('toggleSnackbar')
            })
            .catch(error => console.log(error))
            .finally(() => this.loading = false)
        },
        sendInvoice: function(user){
            this.loading = true
            this.$axios.get('/users/'+user.id+'/bookings')
            .then(response => {
                let accountBookings = response.data
                let totalCost = 0
                accountBookings.forEach(bookings => totalCost += this.calculateCostofPerson(user, bookings))
                this.$axios.post('/users/'+user.id+'/createInvoice', {
                    amount: totalCost
                })
                .then(response => {
                    const invoice = response.data
                    this.invoices.push(invoice) // invoice should be seen in panel even if amount is 0
                    if(invoice.amount > 0){ // only send confirmation when they booked some courses
                       this.$axios.get('/users/'+user.id+'/persons') // we need to get the person objects. accountBookings only has IDs
                       .then(response => {
                           let persons = response.data
                           persons.forEach(person => {
                               person.bookings = []
                               let personBookings = accountBookings.find(bookingsObject => bookingsObject.personId == person.id)
                               if (personBookings) { // there may be persons without bookings
                                   personBookings.courses.forEach(courseId => {
                                       const course = this.$store.getters.getCourseById(courseId)
                                       if (course.confirmed) {
                                           person.bookings.push(course.title)
                                       }
                                   })
                               }
                           })
                           this.$axios.post('/users/' + user.id + '/sendCourseConfirmation', {
                                email: user.email,
                                persons: persons,
                                delay: 1000
                            })
                            .then(response => {
                                console.log(response)
                                this.$axios.post('/users/' + user.id + '/sendInvoice', {
                                    email: user.email,
                                    amount: invoice.amount,
                                    invoiceId: invoice.id,
                                    delay: 1000
                                })
                                .then(response => {
                                    console.log(response)
                                    alert('Kursbestätigung & Rechnung gesendet an '+user.email)
                                })
                                .catch(error => console.log(error))
                                .finally(() => this.loading = false)
                            })
                            .catch(error => console.log(error))

                       })
                       .catch(error => console.log(error))
                   } else {
                       this.loading = false
                   }
                })
                .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
        },
        calculateCostofPerson: function(user, bookings){
            let costForChild = 0
            bookings.courses.forEach(courseId => {
                let course = this.$store.getters.getCourseById(courseId)
                if(course.confirmed)
                {
                    if(user.isConfirmedMember){
                            costForChild += course.costMember
                        } else {
                            costForChild += course.costNonMember
                        }
                }
            })
            return costForChild
        }
    },
    created: function(){
        this.fetchData()
    }
}
</script>