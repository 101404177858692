<template>
    <!-- create & delete courses -->
    <v-layout wrap mt-2>
        <!-- phase -->
        <v-flex xs12>
            <h5 class="headline teal--text">{{ currentPhaseText }}</h5>
        </v-flex>
        <!-- courses -->
        <v-flex xs12>
            <v-data-table :headers="headers" :items="$store.state.courses" hide-default-footer disable-pagination class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.title }}</td>
                        <td>{{ formatCourseDateTime(item) }}</td>
                        <td v-if="$store.getters.isBeforePhase1">
                            <v-btn text icon color="error" @click="deleteCourseDialog(item)">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
        <!-- delete course dialog -->
        <v-dialog v-model="deleteCourseVisible" scrollable persistent :overlay="true" max-width="500px">
            <v-card>
                <v-card-title class="headline error--text">Kurs löschen</v-card-title>
                <v-card-text class="subheading" v-if="courseToDelete">
                    Möchten Sie den Kurs <i>{{ courseToDelete.title }}</i> wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text="text" @click="deleteCourseVisible = false; courseToDelete = null">
                        Abbrechen
                    </v-btn>
                    <v-btn color="error" text="text" @click="deleteCourse(courseToDelete.id)">
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-flex xs12 v-if="$store.getters.isBeforePhase1">
            <v-layout justify-center>
                <NewCourseDialog ref="newCourseDialog" @newCourse="createNewCourse" />
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import NewCourseDialog from '@/components/admin/courses/NewCourseDialog.vue'

export default {
    name: 'ManageCourses',
    components: {
      NewCourseDialog
    },
    data: () => ({
        deleteCourseVisible: false,
        courseToDelete: null,
        headers: [
            { text: 'Titel', value: 'title' },
            { text: 'Wann', value: 'start'},
            { text: 'Löschen'},
        ]
    }),
    computed: {
        currentPhaseText: function(){
            if(this.$store.getters.isBeforePhase1){
                return 'Noch ' + this.daysUntilPhase1 + ' Tage bis Phase 1'
            } else {
                return 'Noch ' + this.daysUntilPhase3 + ' Tage bis Phase 3'
            }
        },
        daysUntilPhase1: function(){
            return this.$moment(this.$store.state.ferienpass.phase1).diff(this.$moment(), 'days')
        },
        daysUntilPhase3: function(){
            return this.$moment(this.$store.state.ferienpass.phase3).diff(this.$moment(), 'days')
        },
    },
    methods: {
        deleteCourse: function(id){
            this.$store.commit('closeSnackbar')
            this.$axios.delete('/course/' + id)
            .then(response => {
                this.$store.commit('setSnackbarColor', 'success')
                this.$store.commit('setSnackbarText', response.data)
                this.$store.commit('removeCourse', id)
            })
            .catch(error => {
                this.$store.commit('setSnackbarColor', 'error')
                this.$store.commit('setSnackbarMultiLine', true)
                this.$store.commit('setSnackbarText', error.response.data)
            })
            .finally(() => {
                this.$store.commit('toggleSnackbar')
                this.courseToDelete = null
                this.deleteCourseVisible = false
            })
        },
        createNewCourse: function(course){
            course.eventId = this.$store.state.ferienpass.id
            course.start = this.$moment(course.startDate+' '+course.startsAt).utc().toDate()
            course.end = this.$moment(course.endDate+' '+course.endsAt).utc().toDate()
            course.costMember = Math.round(Number.parseFloat(course.costMember).toFixed(2) * 100)
            course.costNonMember = Math.round(Number.parseFloat(course.costNonMember).toFixed(2) * 100)
            this.$store.commit('closeSnackbar')
            this.$axios.post('/course', course)
            .then(response => {
                this.$store.commit('setSnackbarText', 'Kurs '+response.data.title+' erfolgreich erstellt')
                this.$store.commit('setSnackbarColor', 'success')
                this.$store.commit('addCourse', response.data)
                this.$refs.newCourseDialog.resetNewCourse()
            }).catch(error => {
                this.$store.commit('setSnackbarMultiLine', true)
                this.$store.commit('setSnackbarText', error.response.data)
                this.$store.commit('setSnackbarColor', 'error')
                // so the cost is not displayed with 2 extra zeros when an error occurs
                this.$refs.newCourseDialog.newCourse.costMember = Math.round(Number.parseFloat(this.$refs.newCourseDialog.newCourse.costMember).toFixed(2) / 100)
                this.$refs.newCourseDialog.newCourse.costNonMember = Math.round(Number.parseFloat(this.$refs.newCourseDialog.newCourse.costNonMember).toFixed(2) / 100)

            }).finally(() => this.$store.commit('toggleSnackbar'))
        },
        deleteCourseDialog: function(course){
            this.courseToDelete = course
            this.deleteCourseVisible = true
        },
        formatCourseDateTime: function(course){
            let dateString = this.$moment(course.start).format('DD. MMMM')
            if(course.start != course.end) {
                dateString += ' bis ' + this.$moment(course.end).format(' DD. MMMM YYYY')
            } else {
                dateString += ' ' + this.$moment(course.start).format('YYYY')
            }
            dateString += ' von ' + course.startsAt + ' Uhr bis ' + course.endsAt + ' Uhr'
            return dateString
        }
    }
}
</script>