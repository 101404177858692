<template>
    <!-- v-if to prevent error while rendering page -->
    <v-menu offset-y v-if="$store.state.user">
        <template v-slot:activator="{ on }">
            <v-btn class="text-none" text large v-on="on">{{ $store.state.user.email }}</v-btn>
        </template>
        <v-list>
            <v-list-item to="/my/courses" avatar active-class>
                <v-list-item-avatar>
                    <v-icon>shopping_cart</v-icon>
                </v-list-item-avatar>
                <v-list-item-title active-class>Meine Kurse</v-list-item-title>
            </v-list-item>
            <v-list-item to="/my/invoices" avatar active-class>
                <v-list-item-avatar>
                    <v-icon>payment</v-icon>
                </v-list-item-avatar>
                <v-list-item-title active-class>Meine Rechnungen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/my/account" avatar active-class>
                <v-list-item-avatar>
                    <v-icon>settings_applications</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Mein Account</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$store.getters.isAdmin" to="/admin/overview" avatar active-class>
                <v-list-item-avatar>
                    <v-icon>build</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Admin</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$store.dispatch('logout')" avatar active-class>
                <v-list-item-avatar>
                    <v-icon>exit_to_app</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'AccountDropdown',
}
</script>