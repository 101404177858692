<template>
    <v-layout wrap mt-2>
        <!-- to confirm -->
        <v-flex xs12>
            <CoursesToConfirm />
        </v-flex>
        <!-- to clarify or cancel -->
        <v-flex xs12>
            <NotEnoughParticipants />
        </v-flex>
    </v-layout>
</template>

<script>
import CoursesToConfirm from '@/components/admin/review/CoursesToConfirm.vue'
import NotEnoughParticipants from '@/components/admin/review/NotEnoughParticipants.vue'

export default {
    name: 'Review',
    components: {
        CoursesToConfirm,
        NotEnoughParticipants,
    }
}
</script>
